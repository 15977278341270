import { AxiosResponse } from "axios";
import { baseServiceRequest } from "./baseService";
import { GetMembershipsRequest } from "./requests/memberships";
import { MembershipsData } from "@App/models/membership";

export const getMemberships = async (params: GetMembershipsRequest) => {
  return (await baseServiceRequest({
    path: `/membership/Search`,
    type: "get",
    params,
  })) as AxiosResponse<MembershipsData, any>;
};

export const getMembershipDetailsById = async (id: string) => {
  return (await baseServiceRequest({
    path: `/membership/${id}`,
    type: "get",
    useAuthHeader: true,
  })) as AxiosResponse<any, any>;
};

export const createUserMembership = async (data: any) => {
  return await baseServiceRequest({
    path: `/usermembership`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const addFileUserMembership = async (
  membershipId: string,
  data: any,
) => {
  return await baseServiceRequest({
    path: `/usermembership/${membershipId}/file`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const addWaiverUserMembership = async (
  membershipId: string,
  data: any,
) => {
  return await baseServiceRequest({
    path: `/usermembership/${membershipId}/waiver`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const initiateMembershipPayment = async (data: any) => {
  return await baseServiceRequest({
    path: `/stripe/InitiatePaymentRequest`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};
