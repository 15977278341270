import "./FileUpload.scss";
import { FileUploadProps } from "./FileUploadPropTypes";
import FileUploadLogic from "./FileUploadLogic";
import { config } from "@App/config/config";

const FileUpload = ({
  label,
  files,
  banner,
  hideDefaultInformationMessage,
  onChange,
}: FileUploadProps) => {
  const { validationMessage, informationMessage, handleLocalFileUpload } =
    FileUploadLogic(files, onChange, hideDefaultInformationMessage);

  return (
    <div>
      <div className={"file-upload-label"}>{label}</div>
      {banner && (
        <div className={"file-upload-banner"}>
          {banner.icon && <div>{banner.icon}</div>}
          <div>{banner.text}</div>
        </div>
      )}
      <label htmlFor="UploadFileButton">
        <div
          className={`file-upload-wrapper ${
            label ? "" : "file-upload-wrapper-no-title"
          }`}
          onDrop={(event) => {
            handleLocalFileUpload({ event });
          }}
          onDragOver={(ev) => {
            // Prevent default behavior (Prevent file from being opened)
            ev.preventDefault();
          }}>
          <div className={"file-upload-instructions-wrapper"}>
            <div>
              <svg
                width="69"
                height="70"
                viewBox="0 0 69 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M34.9243 31.8514C34.8739 31.7869 34.8095 31.7348 34.7359 31.699C34.6624 31.6632 34.5816 31.6445 34.4998 31.6445C34.418 31.6445 34.3372 31.6632 34.2637 31.699C34.1901 31.7348 34.1257 31.7869 34.0753 31.8514L26.5284 41.3995C26.4662 41.479 26.4276 41.5743 26.417 41.6747C26.4064 41.775 26.4243 41.8763 26.4686 41.967C26.5129 42.0577 26.5818 42.1341 26.6674 42.1874C26.7531 42.2408 26.852 42.269 26.9529 42.2687H31.9325V58.6023C31.9325 58.8988 32.1751 59.1414 32.4716 59.1414H36.5146C36.811 59.1414 37.0536 58.8988 37.0536 58.6023V42.2755H42.0467C42.4981 42.2755 42.7475 41.7566 42.4712 41.4062L34.9243 31.8514Z"
                  fill="black"
                />
                <path
                  d="M54.6744 25.6321C51.5883 17.4923 43.7247 11.7041 34.5135 11.7041C25.3022 11.7041 17.4387 17.4855 14.3525 25.6254C8.57783 27.1415 4.3125 32.4041 4.3125 38.6572C4.3125 46.103 10.3433 52.1338 17.7823 52.1338H20.4844C20.7809 52.1338 21.0234 51.8912 21.0234 51.5947V47.5518C21.0234 47.2553 20.7809 47.0127 20.4844 47.0127H17.7823C15.5115 47.0127 13.3755 46.1098 11.7853 44.4724C10.2018 42.8417 9.35947 40.645 9.43359 38.3675C9.49424 36.5886 10.1007 34.9175 11.199 33.5092C12.3243 32.0739 13.9011 31.0295 15.653 30.5646L18.2068 29.8975L19.1435 27.4312C19.7229 25.8949 20.5315 24.4597 21.549 23.1592C22.5535 21.8702 23.7434 20.7371 25.0799 19.7968C27.8493 17.8494 31.1106 16.8185 34.5135 16.8185C37.9163 16.8185 41.1776 17.8494 43.9471 19.7968C45.288 20.7401 46.4739 21.8722 47.4779 23.1592C48.4954 24.4597 49.304 25.9017 49.8835 27.4312L50.8134 29.8907L53.3605 30.5646C57.0126 31.5483 59.5664 34.8703 59.5664 38.6572C59.5664 40.8876 58.6972 42.9899 57.1204 44.5667C56.3471 45.3445 55.4273 45.9612 54.4141 46.381C53.4009 46.8009 52.3144 47.0156 51.2177 47.0127H48.5156C48.2191 47.0127 47.9766 47.2553 47.9766 47.5518V51.5947C47.9766 51.8912 48.2191 52.1338 48.5156 52.1338H51.2177C58.6567 52.1338 64.6875 46.103 64.6875 38.6572C64.6875 32.4108 60.4356 27.155 54.6744 25.6321Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className={"file-upload-instructions"}>
              <span className={"file-upload-instructions-title"}>
                Drop or Select files
              </span>
              <span>Drop files here or click to browse</span>
              <span className={"file-upload-instructions-max"}>{`Max. ${
                config.maxFileSizeMB * config.maxImagesCount
              } MB (Up to ${config.maxImagesCount} files, ${
                config.maxFileSizeMB
              } MB max. per file)`}</span>
            </div>
          </div>

          <input
            type="file"
            id="UploadFileButton"
            hidden
            multiple
            onChange={(event) => {
              handleLocalFileUpload({ browseFiles: event.target.files });
            }}
          />
        </div>
      </label>
      {(validationMessage || informationMessage) && (
        <div
          className={`file-upload-info ${
            validationMessage ? "file-upload-info-error" : ""
          }`}>
          <div>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 13.9229H11V15.9229H9V13.9229ZM9 5.92285H11V11.9229H9V5.92285ZM10 0.922852C4.47 0.922852 0 5.42285 0 10.9229C0 13.575 1.05357 16.1186 2.92893 17.9939C3.85752 18.9225 4.95991 19.6591 6.17317 20.1616C7.38642 20.6642 8.68678 20.9229 10 20.9229C12.6522 20.9229 15.1957 19.8693 17.0711 17.9939C18.9464 16.1186 20 13.575 20 10.9229C20 9.60963 19.7413 8.30927 19.2388 7.09602C18.7362 5.88276 17.9997 4.78037 17.0711 3.85178C16.1425 2.9232 15.0401 2.1866 13.8268 1.68406C12.6136 1.18151 11.3132 0.922852 10 0.922852ZM10 18.9229C7.87827 18.9229 5.84344 18.08 4.34315 16.5797C2.84285 15.0794 2 13.0446 2 10.9229C2 8.80112 2.84285 6.76629 4.34315 5.266C5.84344 3.76571 7.87827 2.92285 10 2.92285C12.1217 2.92285 14.1566 3.76571 15.6569 5.266C17.1571 6.76629 18 8.80112 18 10.9229C18 13.0446 17.1571 15.0794 15.6569 16.5797C14.1566 18.08 12.1217 18.9229 10 18.9229Z"
                fill={`${validationMessage ? "red" : "#ffae00"}`}
              />
            </svg>
          </div>
          <div className={"file-upload-text"}>
            {validationMessage || informationMessage}
          </div>
        </div>
      )}
      <div
        id="FileUploadPreviewWrapper"
        className={"file-upload-preview-wrapper"}></div>
    </div>
  );
};

export default FileUpload;
