import axios, { AxiosError, AxiosResponse } from "axios";
import { baseServiceRequest } from "./baseService";
import { UploadFileRequest } from "./requests/uploadFileRequest";
import { PresignedUrl } from "@App/models/fileUpload";
import { Tenant } from "@App/models/tenant";
import { ManagedEntity } from "@App/models/managedEntity";
import { PoiCategoryPagedResponse } from "@App/models/poi";

export const tenantConfigGet = async (): Promise<
  AxiosResponse<Tenant, any>
> => {
  return await baseServiceRequest({
    path: `/app/config/tenant`,
    type: "get",
  });
};

export const presignedUrlGet = async (mediaType: string) => {
  //TODO: Replace with the membership module
  return (await baseServiceRequest({
    path: `/hub/event/PresignedUrl?mediaType=${mediaType}`,
    type: "get",
  })) as AxiosResponse<PresignedUrl, any>;
};

export const fileUpload = async (uploadFileRequest: UploadFileRequest) => {
  return await axios.put(
    `${uploadFileRequest.presignedUrl}`,
    uploadFileRequest.file,
    {
      headers: {
        "Content-Type": "binary/octet-stream",
        "enclosure-type": "multipart/form-data",
      },
    },
  );
};

export const getPoiCategories = async (): Promise<
  AxiosResponse<PoiCategoryPagedResponse, AxiosError>
> => {
  return await baseServiceRequest({
    path: `/poicategory`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getManagedBy = async () => {
  return (await baseServiceRequest({
    path: `/managedBy`,
    type: "get",
  })) as AxiosResponse<ManagedEntity[], any>;
};

export const getSubCommunities = async () => {
  return await baseServiceRequest({
    path: `/subcommunity`,
    type: "get",
  });
};
