import { MembershipDogTermsDummy } from "@Utils/dummy";
import "./MembershipDogTerms.scss";
import MembershipDogTermsLogic from "./MembershipDogTermsLogic";
import { MembershipDogTermsProps } from "./MembershipDogTermsPropTypes";
import { Button, Checkbox } from "@Components/index";

const MembershipDogTerms = (componentProps: MembershipDogTermsProps) => {
  const props = MembershipDogTermsLogic();
  return (
    <div className={"membership-dog-terms"}>
      <div className={"membership-dog-terms-title"}>
        {MembershipDogTermsDummy.title}
      </div>
      <div
        className={"membership-dog-terms-description"}
        dangerouslySetInnerHTML={{
          __html: componentProps.waiver.textContent,
        }}
      />
      <div className={"membership-dog-terms-checkbox"}>
        <Checkbox
          text={`I agree on behalf of ${props.userName}`}
          onChange={props.onCheckboxChange}
        />
      </div>
      <div className={"membership-dog-terms-proceed"}>
        <Button
          isDisabled={!props.termsAccepted}
          text={"Proceed to Payment"}
          onClick={componentProps.onProceedToPayment}
        />
      </div>
    </div>
  );
};

export default MembershipDogTerms;
