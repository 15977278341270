import { EVENT_TICKET_DATE, SHOW_RESULT_BANNER } from "@App/constants/appConstants";
import {
  getEventDetailsData,
  getEventsList,
  handleNotLoggedUserLogin,
  updateEventUserPreferences,
  updateRecurringEventUserPreferences,
} from "@App/store/actions/eventActions";
import { EventCategoriesState } from "@App/store/reducers/eventReducer";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { customEventPublish, isSameDay, monthName } from "@Utils/utils";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Event } from "@App/models/event";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { addWaiver, getWaiverById } from "@App/api/waiver";
import { Waiver } from "@App/models/waiver";
import { UserType } from "@App/models/types";
import { SHOW_REGISTRATION_DETAILS } from "@App/constants/userConstants";
import * as DateFns from "date-fns";
import { useTranslation } from "react-i18next";

const EventDetailsLogic = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event | null>();
  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] = useState(false);
  const [isRsvpPreferenceProcessing, setIsRsvpPreferenceProcessing] = useState(false);
  const [isTicketPurchaseModalOpen, setIsTicketPurchaseModalOpen] = useState(false);
  const [isLoadingWaivers, setIsLoadingWaivers] = useState(false); // eslint-disable-line
  const [isWaiversModalOpen, setIsWaiversModalOpen] = useState(false);
  const [waiverItems, setWaiverItems] = useState<Waiver[] | null>(null);
  const [showCTAs, setShowCTAs] = useState(true);

  const eventId = searchParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isRecurring = useMemo(() => !!searchParams.get("eventRecurrenceStartDate"), [searchParams]);

  const eventCategoriesStore = useSelector<RootState, EventCategoriesState>((state) => state.eventCategories);
  const { eventCategories } = eventCategoriesStore;

  const userData = useSelector<RootState, UserState>((state) => state.userProfile);
  const { userInfo } = userData;

  // Hide CTAs if the event has ended
  useEffect(() => {
    if (!currentEvent) return;

    let endDateString: string;

    if (currentEvent.recurring?.isRecurring && currentEvent.recurring?.terminationDate) {
      endDateString = currentEvent.recurring.terminationDate;
    } else {
      endDateString = currentEvent.endDate;
    }

    const endDate = DateFns.parseISO(endDateString);
    const now = DateFns.startOfToday();

    setShowCTAs(now < endDate);
  }, [currentEvent]);

  useEffect(() => {
    if (userData.userInfo?.firstName) {
      setIsUserLogged(true);
    }
    localStorage.removeItem(EVENT_TICKET_DATE);
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, eventId]);

  const userType = useMemo(() => userInfo?.userType, [userInfo]);

  const getEventDetails = () => {
    if (eventId) {
      getEventDetailsData(eventId).then((response) => {
        if (response) {
          response.ticketingDates = [{ startDate: response.startDate, endDate: response.endDate }];
          setCurrentEvent(response);
        }
        setIsRsvpPreferenceProcessing(false);
        setIsFavPreferenceProcessing(false);
      });
    }
  };

  const getDatetimeValue = (date: Moment) => {
    const month = monthName({
      month: date.get("M"),
      abbreviation: true,
    });
    return `${date.format(`ddd D [${month}]`)}`;
  };

  const getEventStartAndEndDates = (currentEvent: Event) => {
    return {
      startDate: searchParams.get("eventRecurrenceStartDate") ?? currentEvent.startDate,
      endDate: searchParams.get("eventRecurrenceEndDate") ?? currentEvent.endDate,
    };
  };

  const getEventDate = () => {
    if (currentEvent) {
      const eventDates = getEventStartAndEndDates(currentEvent);
      return `${getDatetimeValue(moment(eventDates.startDate))}${
        !isSameDay(eventDates.startDate, eventDates.endDate) ? ` - ${getDatetimeValue(moment(eventDates.endDate))}` : ""
      }`;
    }
  };

  const getEventTime = () => {
    let startTime: string = "";
    let endTime: string = "";
    if (currentEvent) {
      const eventDates = getEventStartAndEndDates(currentEvent);
      startTime = moment(eventDates.startDate).format("h:mm A").replace(":00", "");
      endTime = moment(eventDates.endDate).format("h:mm A").replace(":00", "");
    }
    return `${startTime} - ${endTime}`;
  };

  const getEventCategories = () => {
    let eventCategoriesData: string[] = [];

    eventCategories?.forEach((eventCategory) => {
      if (currentEvent?.categoryIds && currentEvent.categoryIds.indexOf(eventCategory.id) > -1) {
        eventCategoriesData.push(eventCategory.name);
      }
    });

    return eventCategoriesData;
  };

  /**
   * If there are no waivers, it returns early with no exceptions.
   */
  const postWaivers = () => {
    if (!waiverItems?.length) return;
    const waiverReq = [];
    for (let waiver of waiverItems!) {
      const req = addWaiver({
        eventId: currentEvent!.id,
        waiverId: waiver.id,
        userId: userInfo!.id,
      });
      waiverReq.push(req);
    }
    if (waiverReq.length) {
      Promise.all(waiverReq);
    }
  };

  /**
   * Updates the preference, setting the processing flag related to the preference to true.
   * If that is successful:
   * - shows a toast with a success text if the value set is true.
   * - updates the event's details.
   */
  const updateEventPreference = async (preferenceName: string, preferenceValue: boolean) => {
    if (userInfo && currentEvent) {
      if (preferenceName === "isRSVPd") {
        setIsRsvpPreferenceProcessing(true);
      } else {
        setIsFavPreferenceProcessing(true);
      }
      try {
        if (!isRecurring) {
          await updateEventUserPreferences({
            eventId: currentEvent.id,
            userId: userInfo.id,
            preference: { [preferenceName]: preferenceValue },
          });
        } else {
          await updateRecurringEventUserPreferences({
            baseEventId: currentEvent.id,
            userId: userInfo.id,
            bodyData: {
              id: null,
              ...getEventStartAndEndDates(currentEvent),
              eventUser: {
                [preferenceName]: preferenceValue,
              },
            },
          });
        }
        if (preferenceValue) {
          customEventPublish(SHOW_RESULT_BANNER, `You've ${preferenceName === "isRSVPd" ? "RSVP" : "Favorited"} for this event!`);
        }
        getEventDetails();
        dispatch(
          getEventsList({
            startDate: new Date(),
            endDate: new Date(),
            isRefresh: true,
            isUpcoming: true,
          }) as any,
        );
        return true;
      } catch {
        return false;
      }
    }
  };

  useEffect(() => {
    if (currentEvent?.waiverIds?.length) {
      setIsLoadingWaivers(true);
      const getWaiversAsync = async () => {
        let waivers: Waiver[] = [];
        for await (let waiverId of currentEvent?.waiverIds!) {
          const response = await getWaiverById(waiverId);
          waivers.push(response.data);
        }
        return waivers;
      };
      getWaiversAsync().then((waivers) => {
        setWaiverItems(waivers);
        setIsLoadingWaivers(false);
      });
    }
  }, [currentEvent?.waiverIds, setIsLoadingWaivers]);

  const ticketingCtaLabel = useMemo(() => {
    if (!!userType || userType === UserType.Initiated) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        return t("Purchase Tickets");
      }
      if (userType === UserType.Initiated) {
        return "Please Complete Account Setup";
      }
      if (userType === UserType.ResidentPending) {
        return "Resident Verification Pending";
      }
    }
    return t("Log In to Purchase");
  }, [userType]);

  const isCTAButtonDisabled = userType === UserType.ResidentPending;

  const handleTicketPurchase = (eventId?: string) => {
    if (eventId) {
      navigate(`/calendar/event-details?id=${eventId}`);
    }
    setIsRsvpPreferenceProcessing(true);
    setIsTicketPurchaseModalOpen(false);
    getEventDetails();
  };

  const goToTicketingPayment = () => {
    if (currentEvent) {
      navigate(`/calendar/event-details/tickets?id=${currentEvent.id}`);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleWaiversClose = async () => {
    if (currentEvent?.recurring?.isRecurring) {
      setIsTicketPurchaseModalOpen(true);
    } else {
      if (await updateEventPreference("isRSVPd", true)) postWaivers();
    }
    setIsWaiversModalOpen(false);
  };

  const showAccountDetails = () => {
    dispatch({
      type: SHOW_REGISTRATION_DETAILS,
      payload: {
        id: userData.userInfo?.id,
        userVerificationCode: userData.userInfo?.userVerificationCode,
      },
    });
  };

  const handleCTAButton = () => {
    if ((!!userType || userType === UserType.Initiated) && userType !== UserType.ResidentPending) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        if (currentEvent?.recurring?.isRecurring) {
          setIsTicketPurchaseModalOpen(true);
        } else {
          goToTicketingPayment();
        }
      }

      if (userType === UserType.Initiated) {
        showAccountDetails();
        return;
      }
    }

    if (currentEvent) {
      handleNotLoggedUserLogin(currentEvent);
    }
  };

  return {
    waiverItems,
    currentEvent,
    userInfo,
    ticketingCtaLabel,
    showCTAs,

    isUserLogged,
    isCTAButtonDisabled,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,

    isTicketPurchaseModalOpen,
    setIsTicketPurchaseModalOpen,
    isWaiversModalOpen,
    setIsWaiversModalOpen,

    getEventDate,
    getEventTime,
    getEventCategories,

    goBack,
    updateEventPreference,
    handleTicketPurchase,
    goToTicketingPayment,
    handleWaiversClose,
    handleCTAButton,
    getEventStartAndEndDates,
    isRecurring,
  };
};

export default EventDetailsLogic;
