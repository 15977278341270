import { AvailableRateAndTimes } from "@App/models/AvailableRates";
import { formatCurrency } from "@Utils/utils";
import { useState } from "react";
import { format, parseISO } from "date-fns";

type UseRateDropdownsParams = {
  rates?: AvailableRateAndTimes[];
  selectedRate: AvailableRateAndTimes;
  selectedStartTime: {
    label: string;
    value: string;
    endTimes: {
      endTime: string;
      cost: number;
    }[];
  };
  setSelectedRate: (rate: string | null) => void;
  setSelectedStartTime: (startTime: string | null) => void;
  setSelectedEndTime: (endTime: string | null) => void;
};

export const useRateDropdowns = ({
  rates,
  selectedRate,
  selectedStartTime,
  setSelectedRate,
  setSelectedStartTime,
  setSelectedEndTime,
}: UseRateDropdownsParams) => {
  const [calendarCurrentMonth, setCalendarCurrentMonth] = useState(new Date());

  const handleMonthChange = (date: Date, isRefresh?: boolean) => {
    const isSameMonth =
      `${date.getFullYear()}-${date.getMonth()}` ===
      `${calendarCurrentMonth.getFullYear()}-${calendarCurrentMonth.getMonth()}`;

    if (!isSameMonth || isRefresh) {
      setCalendarCurrentMonth(date);
    }
  };

  const resetDropdowns = () => {
    setSelectedRate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
  };

  const handleRateChange = (rate: any) => {
    setSelectedRate(rate);
    setSelectedStartTime(null);
  };

  const handleStartTimeChange = (date: any) => {
    setSelectedStartTime(date);
    setSelectedEndTime(null);
  };

  const handleEndTimeChange = (date: any) => {
    setSelectedEndTime(date);
  };

  const getRateOptions = () => {
    if (!rates || rates?.length === 0) {
      return [];
    }

    const rateOptions = rates.map((rate: any) => {
      return {
        label: `${rate.rateName} (${formatCurrency(rate.rate)}/${
          rate.rateType
        })`,
        value: rate.rateId,
        ...rate,
      };
    });
    return rateOptions;
  };

  const getStartTimes = () => {
    if (selectedRate?.potentialSlots?.length === 0) return [];
    const startTimesOptions = selectedRate?.potentialSlots.map((slot: any) => {
      return {
        label: format(parseISO(slot.startTime), "h:mm aaa"),
        value: slot.startTime,
        endTimes: slot.endTimes,
      };
    });
    return startTimesOptions;
  };

  const getEndTimes = () => {
    if (selectedStartTime?.endTimes?.length === 0) return [];
    const endTimesOptions = selectedStartTime?.endTimes.map(
      ({ endTime, cost }: any) => {
        return {
          label: format(parseISO(endTime), "h:mm aaa"),
          value: endTime,
          cost,
        };
      },
    );
    return endTimesOptions;
  };

  return {
    calendarCurrentMonth,
    handleMonthChange,
    resetDropdowns,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    getRateOptions,
    getStartTimes,
    getEndTimes,
  };
};
