import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@Store/store";
import {
  EventCategoriesState,
  EventsState,
  UpcomingEventsState,
} from "@App/store/reducers/eventReducer";
import { UserState } from "@App/store/reducers/userReducer";
import { useEffect, useState } from "react";
import { getEventsList } from "@App/store/actions/eventActions";
import { EventCategory } from "@App/models/eventCategory";
import {
  DEFAULT_MOBILE_MEDIA_QUERY,
  RSVP_LOGIN_FLOW,
} from "@App/constants/appConstants";
import {
  getEventCategoriesData,
  getUpcomingEvents,
} from "@App/store/actions/eventActions";
import { useSearchParams } from "react-router-dom";
import { usePassedEventCategory } from "@App/hooks";

const CalendarLogic = () => {
  const SCROLL_AMOUNT = 300;
  const dispatch = useDispatch();
  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
      storeEvents: EventsState;
      storeUpcomingEvents: UpcomingEventsState;
      storeEventCategories: EventCategoriesState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
      storeEvents: state.eventsData,
      storeUpcomingEvents: state.upcomingEventsData,
      storeEventCategories: state.eventCategories,
    };
  });

  const { storeUser, storeEvents, storeEventCategories, storeUpcomingEvents } =
    storeData;

  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarCurrentMonth, setCalendarCurrentMonth] = useState(new Date());
  const [searchText, setSearchText] = useState(String);
  const [showCategoryLeftScrollButton, setShowCategoryLeftScrollButton] =
    useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [events, setEvents] = useState(storeEvents.events);
  const [upcomingEvents, setUpcomingEvents] = useState(
    storeUpcomingEvents.upcomingEvents,
  );
  const [categoryFilters, setCategoryFilters] = useState<
    EventCategory[] | null
  >(null);
  const [showCategoryRightScrollButton, setShowCategoryRightScrollButton] =
    useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [hasAttendingOrFavs, setHasAttendingOrFavs] = useState(true);
  const [searchParams] = useSearchParams();
  const [managedByEntityId, setManagedByEntityId] = useState(
    searchParams.get("calendarEntity"),
  );

  const [selectedCategory, setSelectedCategory] = useState("0");
  usePassedEventCategory(
    storeEventCategories.eventCategories,
    setSelectedCategory,
  );

  const handleMonthChange = ({
    date,
    isRefresh,
    managedById,
  }: {
    date: Date;
    isRefresh?: boolean;
    managedById?: string | null;
  }) => {
    setShowCategoryLeftScrollButton(false);
    const isSameMonth =
      `${date.getFullYear()}-${date.getMonth()}` ===
      `${calendarCurrentMonth.getFullYear()}-${calendarCurrentMonth.getMonth()}`;

    if (!isSameMonth || isRefresh) {
      setCalendarCurrentMonth(date);
      setIsProcessing(true);
      dispatch(
        getEventsList({
          startDate: date,
          endDate: date,
          isRefresh,
          managedById: managedById!,
        }) as any,
      );
    }
  };

  useEffect(() => {
    if (!searchParams.get("calendarEntity") && managedByEntityId) {
      handleMonthChange({ date: new Date(), isRefresh: true });
      dispatch(getUpcomingEvents({}) as any);
    } else {
      setManagedByEntityId(searchParams.get("calendarEntity"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    dispatch(getEventCategoriesData() as any);
    dispatch(
      getUpcomingEvents({
        managedById: managedByEntityId!,
      }) as any,
    );
  }, [dispatch, managedByEntityId]);

  useEffect(() => {
    //Check if user is doing the RSVP Login (so that is loads the updated events).
    const pendingRSVPEventData = localStorage.getItem(RSVP_LOGIN_FLOW);
    if (!pendingRSVPEventData) {
      handleMonthChange({
        date: new Date(),
        isRefresh: true,
        managedById: searchParams.get("calendarEntity"),
      });
    }

    const mediaQuery = window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY);

    const handleTabletChange = (e: any) => {
      setIsSmallScreen(e.matches);
    };
    handleTabletChange(mediaQuery);
    mediaQuery.addEventListener("change", handleTabletChange);
    return () => {
      mediaQuery.removeEventListener("change", handleTabletChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterHtmlElement = document.getElementById(
      "calendar-category-filters",
    );
    if (filterHtmlElement) {
      setShowCategoryRightScrollButton(
        filterHtmlElement.scrollWidth !== filterHtmlElement.clientWidth,
      );
    }
  }, [categoryFilters]);

  useEffect(() => {
    if (!isUserLogged && !!storeUser?.userInfo?.firstName) {
      setIsUserLogged(true);
    }

    //Check if user is doing the RSVP Login
    const pendingRSVPEventData = localStorage.getItem(RSVP_LOGIN_FLOW);
    if (pendingRSVPEventData) {
      const eventData = JSON.parse(pendingRSVPEventData);
      const eventDate = new Date(eventData.eventDate);
      handleMonthChange({
        date: eventDate,
        isRefresh: true,
        managedById: searchParams.get("calendarEntity"),
      });
      localStorage.removeItem(RSVP_LOGIN_FLOW);
    }

    setEvents(storeEvents.events);
    setIsProcessing(storeEvents.loading ?? false);

    setUpcomingEvents(storeUpcomingEvents.upcomingEvents);
    const upcommingEventsHasAttendingOrFavs = isUserLogged
      ? storeUpcomingEvents?.upcomingEvents?.filter(
          (event) => event.user?.isFavorite || event.user?.isRSVPd,
        )
      : [];
    setHasAttendingOrFavs(upcommingEventsHasAttendingOrFavs?.length > 0);

    setCategoryFilters(
      storeEventCategories.loading
        ? null
        : storeEventCategories.eventCategories,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    storeUser,
    storeEvents,
    storeUpcomingEvents,
    storeEventCategories,
    calendarCurrentMonth,
  ]);

  const handleScroll = (isLeft?: boolean) => {
    const filterHtmlElement = document.getElementById(
      "calendar-category-filters",
    );

    if (filterHtmlElement) {
      isLeft
        ? (filterHtmlElement.scrollLeft -= SCROLL_AMOUNT)
        : (filterHtmlElement.scrollLeft += SCROLL_AMOUNT);
      setShowCategoryLeftScrollButton(
        isLeft
          ? filterHtmlElement.scrollLeft - SCROLL_AMOUNT > 0
          : filterHtmlElement.scrollWidth > filterHtmlElement.clientWidth,
      );
    }
  };

  return {
    categoryFilters,
    isProcessing,
    isUserLogged,
    selectedDate,
    setSelectedDate,
    handleMonthChange,
    selectedCategory,
    setSelectedCategory,
    events,
    upcomingEvents,
    showCategoryLeftScrollButton,
    setShowCategoryLeftScrollButton,
    showCategoryRightScrollButton,
    searchText,
    setSearchText,
    handleScroll,
    isSmallScreen,
    hasAttendingOrFavs,
  };
};

export default CalendarLogic;
