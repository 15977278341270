import "./EventItem.scss";
import { Heart } from "@Components/icons";
import { Loader, Tag } from "@Components/index";
import moment from "moment";
import EventItemLogic from "./EventItemLogic";
import { monthName } from "@Utils/utils";
import { Link } from "react-router-dom";
import { handleNotLoggedUserLogin } from "@App/store/actions/eventActions";
import { EventItemProps } from "./EventItemPropTypes";
import { useTenantConfig } from "@App/hooks";
import { parseISO } from "date-fns";

const EventItem = ({
  event,
  isCard,
  ignoreAttending,
  showLabel,
  isToday,
  notLoggedUser,
  showDateInformation,
  showCardRSVP,
  showTimeOnly,
  showDateOnly,
  onShowAllClick,
}: EventItemProps) => {
  const props = EventItemLogic(event);

  const placeholderUrl = useTenantConfig()?.features.defaultImagePlaceholder;
  return (
    <>
      {showLabel && (
        <div className={"event-list-item-header"}>
          <div className={"event-list-item-date-label"}>
            {isToday ? "Today" : `${props.eventStartDate.format("dddd Do")}`}
          </div>
          {onShowAllClick && (
            <button
              className={"event-list-item-show-all-events"}
              onClick={() => {
                onShowAllClick();
              }}>
              reset calendar
            </button>
          )}
        </div>
      )}
      <div
        className={`event-list-item ${isCard && "event-list-item-card"} ${
          isToday ? "event-list-item-today" : ""
        }`}
        style={{ flexDirection: isCard ? "column" : "row" }}>
        {props.eventItem.eventType !== "Admin" && (
          <Link
            to={props.getEventLink()}
            className={"event-list-item-image-wrapper"}>
            <Loader
              color={"silver"}
              className={"event-list-item-image-loader"}
            />
            {props.eventItem.files && props.eventItem.files?.length > 0 ? (
              <img
                className={`event-list-item-image ${
                  isCard && "event-list-item-image-card"
                }`}
                src={props.eventItem.files[0].url}
                alt=""
              />
            ) : (
              <img
                className={`event-list-item-image ${
                  isCard && "event-list-item-image-card"
                }`}
                src={placeholderUrl ?? ""}
                alt=""
              />
            )}
            {!notLoggedUser &&
              isCard &&
              !ignoreAttending &&
              (props.eventItem.user?.isRSVPd ||
                props.eventItem.user?.isAttending) && (
                <div className={"event-list-item-image-tag"}>
                  <Tag
                    backgroundColor={"#CFF5D5"}
                    text={"Attending"}
                    isStatusTag={true}
                  />
                </div>
              )}
          </Link>
        )}

        <div
          className={`event-list-item-info ${
            isCard ? "event-list-item-info-card" : ""
          }`}>
          <Link
            type="text"
            to={props.getEventLink()}
            className={`event-list-item-date-wrapper ${
              isCard ? "event-list-item-date-wrapper-card" : ""
            }`}>
            <div className={"event-list-item-date-time-datetime"}>
              {(showDateOnly || isCard || showDateInformation) &&
                !showTimeOnly && (
                  <div className={"event-list-item-date-time-month"}>
                    {monthName({
                      month: moment(props.eventItem.startDate).get("M"),
                      abbreviation: true,
                    })}
                  </div>
                )}
              {(!showDateOnly && !isCard && !showDateInformation) ||
              showTimeOnly
                ? props.eventItem.startDate === props.eventItem.endDate
                  ? "All Day"
                  : moment(props.eventItem.startDate)
                      .format("h:mm")
                      .replace(":00", "")
                : moment(props.eventItem.startDate).format("D")}
              <div className={"event-list-item-date-time-ext"}>
                {(!showDateOnly && !isCard && !showDateInformation) ||
                showTimeOnly
                  ? props.eventItem.startDate === props.eventItem.endDate
                    ? "All Day"
                    : moment(props.eventItem.startDate).format("A")
                  : moment(props.eventItem.startDate).format("ddd")}
              </div>
            </div>
          </Link>

          <Link
            to={props.getEventLink()}
            className={`event-list-item-details-wrapper ${
              isCard ? "event-list-item-details-wrapper-card" : ""
            }`}>
            {props.eventItem?.title && (
              <div
                className={`event-list-item-name ${
                  isCard ? "event-list-item-name-card" : ""
                }`}>
                {props.eventItem?.title}
              </div>
            )}
            {(showDateOnly || isCard || showDateInformation) &&
              !showTimeOnly && (
                <div className={"event-list-item-detail-text"}>
                  {props.eventItem.startDate === props.eventItem.endDate
                    ? "All Day"
                    : `${moment(props.eventItem.startDate).format(
                        "h:mm A",
                      )} - ${moment(props.eventItem.endDate).format("h:mm A")}`}
                </div>
              )}
            {(props.eventItem.venue || props.eventItem.address?.address1) && (
              <span
                className={
                  "event-list-item-detail-text event-list-item-link event-list-item-location"
                }>{`${isCard ? "Location: " : ""}${
                props.eventItem.venue || props.eventItem.address?.address1
              }`}</span>
            )}
          </Link>
          <div
            className={`event-list-item-state-wrapper ${
              isCard && "event-list-item-state-wrapper-card"
            } ${
              !isCard &&
              notLoggedUser &&
              "event-list-item-state-wrapper-not-logged"
            }`}>
            {!notLoggedUser &&
              (props.eventItem.user?.isRSVPd ||
                props.eventItem.user?.isAttending) &&
              !isCard && (
                <Tag
                  backgroundColor={"#CFF5D5"}
                  text={"Attending"}
                  isStatusTag={true}
                />
              )}

            {!notLoggedUser &&
              props.eventItem.eventType !== "Admin" &&
              ((!ignoreAttending && !props.eventItem.user?.isRSVPd) ||
                ignoreAttending) && (
                <button
                  className={"event-list-item-fav"}
                  style={{
                    marginTop: props.isFavPreferenceProcessing ? 5 : 0,
                  }}
                  disabled={props.isFavPreferenceProcessing}
                  onClick={() => {
                    props.updateEventPreference(
                      "isFavorite",
                      !props.eventItem.user?.isFavorite,
                    );
                  }}>
                  {props.isFavPreferenceProcessing ? (
                    <Loader color={"silver"} width="20" height="10" />
                  ) : (
                    <Heart isActive={props.eventItem.user?.isFavorite} />
                  )}
                </button>
              )}
            {!props.areCommunityUserAccountsDisabled &&
              (!isCard || showCardRSVP) &&
              props.eventItem.eventType !== "Admin" &&
              props.eventItem.isRsvpEnabled &&
              !props.eventItem.user?.isRSVPd &&
              parseISO(props.eventItem.startDate) > new Date() && (
                <button
                  className={
                    "event-list-item-state-button event-list-item-link"
                  }
                  style={{
                    marginBottom: props.isRsvpPreferenceProcessing ? 10 : 0,
                    marginRight: props.isRsvpPreferenceProcessing ? 20 : 0,
                  }}
                  disabled={props.isRsvpPreferenceProcessing}
                  onClick={() => {
                    if (notLoggedUser) {
                      handleNotLoggedUserLogin(props.eventItem);
                    } else {
                      props.updateEventPreference(
                        "isRSVPd",
                        !props.eventItem.user?.isRSVPd,
                      );
                    }
                  }}>
                  {props.isRsvpPreferenceProcessing ? (
                    <Loader color={"silver"} width="20" height="10" />
                  ) : (
                    `${notLoggedUser ? "Login to Confirm" : "Confirm"}`
                  )}
                </button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventItem;
