import { Navbar } from "@Layouts/index";
import ClubsAndSocialsDetailsLogic from "./ClubsAndSocialsDetailsLogic";
import { GenericDetails, Loader } from "@Components/index";

const ClubsAndSocialsDetails = () => {
  const { data, isLoading, goBack } = ClubsAndSocialsDetailsLogic();
  return (
    <Navbar>
      {isLoading && !data ? (
        <Loader
          text={"Loading, please wait"}
          color={"#000"}
          fontColor={"#000"}
          horizontallyCentered
          padding={"100px 20px"}
        />
      ) : (
        <GenericDetails
          details={{
            id: data?.data.id,
            title: data?.data.title,
            description: data?.data.description,
            tags: ["Groups and Clubs"],
            files: data?.data.files,
          }}
          goBack={goBack}
          // onFavClick={updateFavorite}
          isFavProcessing={false} //TODO: No BE for this at this moment
        />
      )}
    </Navbar>
  );
};

export default ClubsAndSocialsDetails;
