import { useEffect, useState } from "react";
import { MembershipModalProps } from "./MembershipModalPropTypes";
import { useNavigate } from "react-router-dom";
import { addWaiverUserMembership } from "@App/api/memberships";
import { UserType } from "@App/models/types";
import { sendMobileViewModePostMessage } from "@Utils/utils";

const MembershipModalLogic = (componentProps: MembershipModalProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [newUserMembershipId, setNewUserMembershipId] = useState<string | null>(
    null,
  );
  const [userId, setUserId] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    sendMobileViewModePostMessage({
      isLoading: isProcessing,
      text: "Processing, please wait",
    });
  }, [isProcessing]);

  useEffect(() => {
    setIsVisible(componentProps.isVisible);
  }, [componentProps]);

  const onClose = () => {
    componentProps.onClose(true);
    setCurrentStep(1);
  };

  const onStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const onContinue = async (userMembershipId: string, userId: string) => {
    onStepChange(2);
    setNewUserMembershipId(userMembershipId);
    setUserId(userId);
  };

  const onProceedToPayment = async () => {
    setIsProcessing(true);

    const addWaiverRequest = {
      id: newUserMembershipId!,
      membershipId: componentProps.membershipDetails.id,
      userId: userId!,
      waiverId: componentProps.membershipDetails?.waivers[0].id,
    };
    const currentUserType = UserType[componentProps.userType!];
    const rateId = componentProps.membershipDetails?.rates.find((rate: any) =>
      rate.userTypeIds.includes(currentUserType),
    )?.id;
    await addWaiverUserMembership(newUserMembershipId!, addWaiverRequest);
    navigate(
      "/memberships/membership-payment/" + newUserMembershipId! + "/" + rateId,
    );
    setIsProcessing(false);
  };

  return {
    isVisible,
    currentStep,
    isProcessing,
    onClose,
    setIsVisible,
    onStepChange,
    onProceedToPayment,
    setNewUserMembershipId,
    onContinue,
  };
};

export default MembershipModalLogic;
