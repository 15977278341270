//TODO: Refactor this component, it has 600+ lines of code.
import {
  Breadcrumb,
  Button,
  ImageFilesCarousel,
  LinkifyText,
  Modal,
  TicketPurchase,
  StripeElements,
  BackButton,
  Checkbox,
  Loader,
  PromptsModal,
  WaiversModal,
} from "@Components/index";

import "./Payments.scss";
import PaymentsLogic from "./PaymentsLogic";
import { Information, Minus, Plus } from "@Components/icons";
import { PaymentsProps } from "./PaymentsPropTypes";
import { Event } from "@App/models/event";
import { formatCurrency, formatPercentage } from "@Utils/utils";
import { Elements } from "@stripe/react-stripe-js";
import { format, parseISO } from "date-fns";
import { config } from "@App/config/config";

const Payments = (componentProps: PaymentsProps) => {
  const props = PaymentsLogic(componentProps);

  const isMembership = componentProps.paymentData?.type === "membership";
  const isBooking = componentProps.paymentData?.type === "booking";
  const isAdminEvent = componentProps.paymentData?.type === "admin";
  const isTicketedEvent = componentProps.paymentData?.type === "ticket";
  const isEvent = isAdminEvent || isTicketedEvent;

  // If there is no userInfo, the user is not logged.
  // We should not display the payment modal/flow.
  return props.userInfo ? (
    <div className={"payments"}>
      <div className={"payments-breadcrumb"}>
        <Breadcrumb
          initialSection={{ text: "Home", path: "/" }}
          currentSectionName={props.currentPaymentDetails?.title}
          hasIdentificator={true}
          // TODO: This ignoreArray is tight coupled with the route path, probably need to be refactored
          ignoreArray={["membership-payment"]}
        />
      </div>
      {!props.isMobileEmbed && (
        <div className={"payments-navigation-min"}>
          <BackButton goBack={props.goBack} />
        </div>
      )}
      <div className="payments-content">
        {/* This step is only for events and bookings*/}
        {props.purchaseStep === 0 && (
          <>
            <div
              className={
                "payments-text payments-detail-wrapper payments-detail-wrapper-left"
              }>
              <div className={"payments-name"}>
                {props.currentPaymentDetails?.title}
              </div>
              <div className={"payments-datetime"}>
                {isBooking ? null : props.getEventDate()}
              </div>
              {!isAdminEvent && (
                <div className={"payments-carousel"}>
                  <ImageFilesCarousel
                    files={props.currentPaymentDetails?.files || []}
                    customHeight={"174px"}
                  />
                </div>
              )}
              {(props.currentPaymentDetails?.ticketingDates?.length ||
                isBooking) && (
                <div className={"payments-products__bottom-content"}>
                  <div className="content-row">
                    <span className="date-label">Date</span>
                  </div>
                  <div className="content-row">
                    <span>
                      {isBooking && <span>{props.getBookingDate()}</span>}
                      {isEvent && <span>{props.getEventDate()}</span>}
                    </span>
                    {isTicketedEvent && false && (
                      <button
                        className="payments-products__edit-link"
                        onClick={() =>
                          props.setIsTicketPurchaseModalOpen(true)
                        }>
                        Edit
                      </button>
                    )}
                  </div>
                  <div className="content-row">
                    <span className="time-label">Time</span>
                  </div>
                  <div className="content-row">
                    {isBooking && <span>{props.getBookingTime()}</span>}
                    {isEvent && <span>{props.getEventTime()}</span>}
                  </div>
                </div>
              )}
            </div>
            <div
              className={"payments-text payments-detail-wrapper payments-card"}>
              <div className="payments-products-card">
                {isBooking &&
                  props.currentPaymentDetails?.isAdminApprovalRequired && (
                    <div className={"payments-products-card__banner"}>
                      <div>
                        <Information color={"#1890FF"} size={20} />
                      </div>
                      <div className={"payments-products-card__banner__text"}>
                        {`Please note that before this reservation can be considered 'confirmed', your booking request must be reviewed by ${props.tenantName} administration. Once the reservation has been reviewed, you will receive an email with status verification and any additional steps, if required.`}
                      </div>
                    </div>
                  )}
                <div className="payments-products-card__header">
                  <label
                    className="payments-products-card__title"
                    data-cy="payments-card-title">
                    {isBooking ? "Reservation Price" : "Tickets Prices"}
                  </label>
                </div>

                <div className="payments-products-card__list">
                  {!isBooking ? (
                    componentProps.availableTickets
                      ?.filter(({ canBePurchased }) => canBePurchased)
                      .map(({ name, price, id, soldOut, numberAvailable }) => {
                        const ticketAmount =
                          props.ticketAmount?.find((val) => val.id === id)
                            ?.amount ?? 0;
                        return (
                          <div
                            className={`payments-products-card__row ${
                              soldOut ? "disabled" : ""
                            }`}
                            key={id}>
                            <div className="payments-products__type">
                              {name}
                            </div>
                            <div className="payments-products__price">{`${
                              price ? formatCurrency(price) : "Free"
                            }`}</div>
                            <div className="payments-products__selector">
                              {soldOut ? (
                                <span>{"Sold Out"}</span>
                              ) : (
                                <>
                                  <button
                                    onClick={() =>
                                      props.decreaseTicketAmount(id)
                                    }
                                    disabled={ticketAmount === 0}>
                                    <Minus />
                                  </button>
                                  <span>{ticketAmount}</span>
                                  <button
                                    disabled={ticketAmount === numberAvailable}
                                    onClick={() =>
                                      props.increaseTicketAmount(id)
                                    }
                                    data-cy="payments-0-add-ticket-btn">
                                    <Plus />
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div className={`payments-products-card__row`}>
                      <div className="payments-products__type">{"Rate"}</div>
                      <div className="payments-products__price">{`${formatCurrency(
                        componentProps.bookingDate?.cost,
                      )}`}</div>
                    </div>
                  )}
                </div>
                <div className={"payments-addons"}>
                  {props.isLoadingAddOns && <Loader color={"#000"} />}
                  {!props.isLoadingAddOns && props.addOnItems && (
                    <>
                      <div className="payments-products-card__header">
                        <label
                          className="payments-products-card__title payments-products-card__title__addon"
                          data-cy="payments-card-title">
                          Add Ons
                        </label>
                      </div>
                      {props.addOnItems.map((addOnItem, index) => {
                        return (
                          <div
                            key={`addon_item${index}`}
                            className={`payments-products-card__row`}>
                            <div className="payments-products__type">
                              <Checkbox
                                text={addOnItem.title}
                                onChange={(isSelected) =>
                                  props.onAddOnClick(addOnItem.id, isSelected)
                                }
                              />
                            </div>
                            <div className="payments-products__price">
                              {`${formatCurrency(addOnItem.amount)}`}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}

                  <PromptsModal
                    prompts={props.promptsItems ?? []}
                    isVisible={props.showPromptsModal}
                    onContinue={props.handlePromptsClose}
                  />

                  <WaiversModal
                    waivers={props.waiverItems ?? []}
                    isVisible={props.showWaiversModal}
                    userInfo={{
                      firstname: props.userInfo.firstName,
                      lastname: props.userInfo.lastName,
                    }}
                    onContinue={props.handleWaiverAcceptance}
                    isWaiverProcessing={props.isWaiverProcessing}
                  />
                </div>
                {/* DISCOUNT CODE NOT IMPLEMENTED */}
                {/*
                <div className="payments-products-card__inputs">
                  <div className="payments-products-card__inputs__discount">
                    <TextInput
                      type={"text"}
                      label={"Discount Code"}
                      placeholder={"Enter Your Code"}
                      maxLength={20}
                      withMargin
                      value={props.discountCode}
                      onChange={props.setDiscountCode}
                      errorMessage={"Invalid Discount Code"}
                    />
                  </div>
                <div className="payments-products-card__inputs__button">
                    <Button
                      isDisabled={
                        !props.discountCode || props.discountCode.length === 0
                      }
                      text="Apply"
                      onClick={() => alert("TODO: Apply discount code")}
                      isSecondary
                    />
                  </div> 
                  
                </div>
                */}
                <div className="payments-products-card__action payments-products-card__action__button">
                  <Button
                    text={props.page0CTAButtonText}
                    onClick={props.handlePurchaseStep}
                    isDisabled={
                      props.isLoadingPrompts ||
                      props.isLoadingAddOns ||
                      (isTicketedEvent &&
                        props.ticketAmount?.every(
                          ({ amount }) => amount === 0,
                        )) ||
                      props.isProcessingRequest ||
                      props.isPage0CTAButtonDisabled
                    }
                    isLoading={
                      props.isLoadingPrompts ||
                      props.isLoadingAddOns ||
                      props.isProcessingRequest
                    }
                    dataCy="payments-0-proceed-btn"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {props.purchaseStep === 1 && (
          <>
            <div
              className={"payments-text payments-detail-wrapper payments-card"}>
              <div className="payments-products-card">
                <div className="payments-products-card__header">
                  <label
                    className="payments-products-card__title"
                    data-cy="payments-card-title">
                    {isBooking ? "My Reservations" : "My Basket"}
                  </label>
                </div>

                {isMembership && (
                  <div className="payments-products-card__list">
                    {componentProps.membership.cart?.items.map(
                      (item: any, i: number) => (
                        <div
                          className="payments-products-card__row_payment"
                          key={i}>
                          <div className="payments-products__type payments-products__label_payment">
                            {item.description}
                          </div>
                          <div className="payments-products__type payments-products__label_payment">
                            {`${item.details}`}
                          </div>
                          <div className="payments-products__price payments-products__label_payment">{`${formatCurrency(
                            item.totalPrice,
                          )}`}</div>
                        </div>
                      ),
                    )}
                  </div>
                )}
                {isBooking ? (
                  <div className="payments-products-card__list">
                    {props.bookingRequestData?.cart.items.map(
                      (item: any, index: number) => (
                        <div
                          key={`cart_item${index}`}
                          className="payments-products-card__row_payment">
                          <div className="payments-products__type payments-products__label_payment">
                            {item.description}
                          </div>

                          {item.dateTime ? (
                            <div className="payments-products__time payments-products__label_payment">
                              {`${format(
                                parseISO(item.dateTime),
                                "MM-dd-yyyy",
                              )} - ${props.getBookingTime()}`}
                            </div>
                          ) : (
                            <div className="payments-products__time payments-products__label_payment"></div>
                          )}

                          <div className="payments-products__price payments-products__label_payment">
                            {item.details}
                          </div>
                          <div className="payments-products__type payments-products__label_payment">
                            {`${formatCurrency(item.itemPrice)}`}
                          </div>
                        </div>
                      ),
                    )}
                    {props.bookingRequestData?.cart.salesTaxPercentage > 0 && (
                      <div className="payments-products-card__row_payment">
                        <div className="payments-products__type payments-products__label_payment">
                          {`Sales Tax ${formatPercentage(
                            props.bookingRequestData?.cart.salesTaxPercentage,
                          )}`}
                        </div>
                        <div className="payments-products__time payments-products__label_payment"></div>
                        <div className="payments-products__type payments-products__label_payment"></div>
                        <div className="payments-products__price payments-products__label_payment">{`${formatCurrency(
                          props.bookingRequestData?.cart.salesTaxAmount,
                        )}`}</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="payments-products-card__list">
                    {props.ticketRequestData?.cart.items.map(
                      (item: any, i: number) =>
                        item.quantity > 0 ? (
                          <div
                            className="payments-products-card__row_payment"
                            key={i}>
                            {props.currentPaymentDetails?.ticketingDates &&
                              props.currentPaymentDetails.ticketingDates
                                .length > 1 && (
                                <button
                                  className="payments-products__edit-link payments-products__edit-link__payment"
                                  onClick={() =>
                                    props.setIsTicketPurchaseModalOpen(true)
                                  }>
                                  Edit
                                </button>
                              )}
                            <div className="payments-products__type payments-products__label_payment">
                              {item?.description}
                            </div>
                            {!isMembership ? (
                              <div className="payments-products__time payments-products__label_payment">
                                {props.getEventTime()}
                              </div>
                            ) : (
                              <div className="payments-products__time payments-products__label_payment"></div>
                            )}
                            <div className="payments-products__type payments-products__label_payment">
                              {`${!isMembership ? item.quantity : ""} ${
                                item.details
                              }`}
                            </div>
                            <div className="payments-products__price payments-products__label_payment">{`${formatCurrency(
                              item.totalPrice,
                            )}`}</div>
                          </div>
                        ) : null,
                    )}
                    {props.ticketRequestData?.cart.salesTaxPercentage > 0 && (
                      <div className="payments-products-card__row_payment">
                        <div className="payments-products__type payments-products__label_payment">
                          {`Sales Tax ${formatPercentage(
                            props.ticketRequestData?.cart.salesTaxPercentage,
                          )}`}
                        </div>
                        <div className="payments-products__time payments-products__label_payment"></div>
                        <div className="payments-products__type payments-products__label_payment"></div>
                        <div className="payments-products__price payments-products__label_payment">{`${formatCurrency(
                          props.ticketRequestData?.cart.salesTaxAmount,
                        )}`}</div>
                      </div>
                    )}
                  </div>
                )}

                <div className="payments-products-card__total">
                  <div className="ticket-total">
                    <span>TOTAL</span>{" "}
                    <span>{props.calculateTotalPrice()}</span>
                  </div>
                </div>
                <div className="payments-products-card__payment">
                  <div className="row header">
                    <div className="title">Payment</div>
                  </div>
                </div>
                <div className="payments-products-card__action">
                  <div className="box box-stripe">
                    <div className="inputs">
                      <Elements
                        stripe={props.stripePromise}
                        options={{
                          clientSecret: props.getClientSecret(),
                        }}>
                        <StripeElements
                          isDisabled={
                            props.calculateTotalPrice() === formatCurrency(0) ||
                            !props.stripePromise
                          }
                          nextStep={props.handlePaymentSuccess}
                          redirectUrl={props.redirectUrl}
                          clientSecret={props.getClientSecret()}
                        />
                      </Elements>
                    </div>

                    {/* TODO: We need to add the default Cecilian for now https://app.clickup.com/t/2242234/CBP-2616,
                        and https://app.clickup.com/t/2242234/CBP-2595 after it's ready to use first the tenant one
                        and if null, the default one. */}
                    <LinkifyText
                      text={`<p>By clicking "Pay order" I confirm I have read 
                    <a href=${config.defaultPrivacyPolicy} target="_blank"><strong>${props.tenantName}</strong></a> Privacy Notice and Cookie
                    Notice. I agree to the terms & conditions of ${props.tenantName}.</p>`}
                      useBoldLinks
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {props.purchaseStep === 2 && (
          <>
            <div
              className={
                "payments-text payments-detail-wrapper payments-detail-wrapper-left"
              }>
              <div className={"payments-name"}>
                {props.currentPaymentDetails?.title}
              </div>
              {!isMembership && !isBooking && (
                <div className={"payments-datetime"}>Ticket purchased</div>
              )}
              {!isAdminEvent && (
                <div className={"payments-carousel"}>
                  <ImageFilesCarousel
                    files={props.currentPaymentDetails?.files || []}
                    customHeight={"174px"}
                  />
                </div>
              )}

              <div className={"payments-products__bottom-content"}>
                {isMembership ? (
                  <>
                    <div>PARK</div>
                    <div className={"payments-bold"}>
                      {props.currentPaymentDetails?.dogPark}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="content-row">
                      <span className="date-label">Date</span>
                    </div>
                    <div className="content-row mb-16">
                      <span>
                        {isBooking && <span>{props.getBookingDate()}</span>}
                        {isEvent && <span>{props.getEventDate()}</span>}
                      </span>
                    </div>
                    <div className="content-row">
                      <span className="time-label">Time</span>
                    </div>
                    <div className="content-row">
                      <span>
                        {isBooking && <span>{props.getBookingTime()}</span>}
                        {isEvent && <span>{props.getEventTime()}</span>}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className={"payments-text payments-detail-wrapper payments-card"}>
              <div className="payments-products-card">
                <div className="payments-products-card__header">
                  <label
                    className="payments-products-card__title"
                    data-cy="payments-card-title">
                    {isMembership &&
                      "You have successfully applied for this membership."}
                    {isBooking &&
                      "You have successfully applied for this reservation."}
                    {isTicketedEvent &&
                      `You have successfully ${
                        props.noPaymentRequired ? "reserved" : "purchased"
                      } this ticket.`}
                  </label>
                </div>

                <div className="payments-products-card__list">
                  {isTicketedEvent && (
                    <>
                      {props.ticketRequestData?.cart.items.map(
                        (item: any, i: number) =>
                          item.quantity > 0 ? (
                            <div
                              className="payments-products-card__row"
                              key={i}>
                              <div className="payments-products__type">
                                {item.details}
                              </div>
                              <div className={`payments-products__type`}>
                                {item.quantity}
                              </div>
                              <div className="payments-products__price">{`${formatCurrency(
                                item.totalPrice,
                              )}`}</div>
                            </div>
                          ) : null,
                      )}
                      {props.ticketRequestData?.cart.salesTaxPercentage > 0 && (
                        <div className="payments-products-card__row">
                          <div className="payments-products__type">
                            {`Sales Tax ${formatPercentage(
                              props.ticketRequestData?.cart.salesTaxPercentage,
                            )}`}
                          </div>
                          <div className="payments-products__type"></div>
                          <div className="payments-products__price">{`${formatCurrency(
                            props.ticketRequestData?.cart.salesTaxAmount,
                          )}`}</div>
                        </div>
                      )}
                    </>
                  )}

                  {isBooking && (
                    <>
                      {props.bookingRequestData?.cart.items.map(
                        (item: any, i: number) =>
                          item.quantity > 0 ? (
                            <div
                              className="payments-products-card__row"
                              key={i}>
                              <div className="payments-products__type">
                                {item.details}
                              </div>
                              <div className={`payments-products__type`}>
                                {item.quantity}
                              </div>
                              <div className="payments-products__price">{`${formatCurrency(
                                item.totalPrice,
                              )}`}</div>
                            </div>
                          ) : null,
                      )}
                      {props.bookingRequestData?.cart.salesTaxPercentage >
                        0 && (
                        <div className="payments-products-card__row">
                          <div className="payments-products__type">
                            {`Sales Tax ${formatPercentage(
                              props.bookingRequestData?.cart.salesTaxPercentage,
                            )}`}
                          </div>
                          <div className="payments-products__type"></div>
                          <div className="payments-products__price">{`${formatCurrency(
                            props.bookingRequestData?.cart.salesTaxAmount,
                          )}`}</div>
                        </div>
                      )}
                    </>
                  )}

                  {isMembership && (
                    <>
                      {componentProps?.membership?.cart.items.map(
                        (item: any, i: number) =>
                          item.quantity > 0 ? (
                            <div
                              className="payments-products-card__row"
                              key={i}>
                              <div className="payments-products__type">
                                {`Membership:`}
                              </div>
                              <div
                                className={`payments-products__type payments-products__type__bold`}>
                                {item.details}
                              </div>
                              <div className="payments-products__price">{`${formatCurrency(
                                item.totalPrice,
                              )}`}</div>
                            </div>
                          ) : null,
                      )}
                      {componentProps.membership?.cart.salesTaxPercentage >
                        0 && (
                        <div className="payments-products-card__row">
                          <div className="payments-products__type">
                            {`Sales Tax ${formatPercentage(
                              componentProps.membership?.cart
                                .salesTaxPercentage,
                            )}`}
                          </div>
                          <div className="payments-products__type"></div>
                          <div className="payments-products__price">{`${formatCurrency(
                            componentProps.membership?.cart.salesTaxAmount,
                          )}`}</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="payments-products-card__total">
                  <div className="ticket-total">
                    <span>TOTAL</span>{" "}
                    <span>{props.calculateTotalPrice()}</span>
                  </div>
                </div>
                <div className="payments-products-card__confirmation">
                  <LinkifyText
                    text={
                      isMembership || isBooking
                        ? `A confirmation email will be sent when your application is 
                          approved to <br/>${props.userInfo?.email}`
                        : `A confirmation email has been sent to <br/> ${props.userInfo?.email}`
                    }
                    useBoldLinks
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {props.currentPaymentDetails && isTicketedEvent && (
        <Modal isVisible={props.isTicketPurchaseModalOpen}>
          <TicketPurchase
            eventData={props.currentPaymentDetails as unknown as Event}
            onClose={props.handleTicketPurchase}
          />
        </Modal>
      )}
    </div>
  ) : null;
};

export default Payments;
