import {
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_LOGOUT,
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_ERROR,
  USER_REGISTRATION_DETAILS_REQUEST,
  USER_REGISTRATION_DETAILS_SUCCESS,
  USER_REGISTRATION_DETAILS_ERROR,
  USER_CODE_VERIFICATION_SUCCESS,
  USER_CODE_VERIFICATION_ERROR,
  USER_CODE_VERIFICATION_REQUEST,
  USER_SEND_VERIFICATION_EMAIL_REQUEST,
  USER_SEND_VERIFICATION_EMAIL_SUCCESS,
  USER_SEND_VERIFICATION_EMAIL_ERROR,
} from "@App/constants/userConstants";
import {
  codeVerification,
  completeUserAccountDetails,
  getUser,
  registerUser,
  userLoginValidation,
  userPasswordSet,
  verifyEmail,
} from "@Api/user";
import { Auth } from "aws-amplify";
import userSession from "@App/auth/userSession";
import { User } from "@App/models/user";
import { config } from "@App/config/config";
import {
  RSVP_LOGIN_FLOW,
  SHOW_RESULT_BANNER,
} from "@App/constants/appConstants";
import { updateEventUserPreferences } from "./eventActions";
import { customEventPublish, setSentryContext } from "@Utils/utils";
import {
  CompleteUserAccountDetailsRequest,
  RegistrationRequest,
  SetUserPasswordRequest,
  UserLoginValidationRequest,
} from "@App/api/requests/user";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";

export const createUserAccount =
  (data: RegistrationRequest) =>
  async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: USER_REGISTRATION_REQUEST,
      });
      const registrationRequest = {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        password: data.password,
        isMarketingEnabled: data.isMarketingEnabled,
        leadSource: config.leadSource,
      };

      userSession.userData = {
        userId: "",
        firstname: registrationRequest.firstname,
        lastname: registrationRequest.lastname,
        email: registrationRequest.email,
      };

      const response = await registerUser(registrationRequest);
      const isSuccess = response.status === 200;

      //Login the user to cognito (Amplify)
      if (isSuccess) {
        Auth.signIn(data.email, data.password)
          .then((userInfo) => {
            userSession.accessToken =
              userInfo.signInUserSession.accessToken.jwtToken;
            userSession.cognitoUsername = userInfo.username;
            dispatch(getUserDetails() as any);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      dispatch({
        type: isSuccess ? USER_REGISTRATION_SUCCESS : USER_REGISTRATION_ERROR,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: USER_REGISTRATION_ERROR,
        payload: `${error.response ? error.response.status : error}`,
      });
    }
  };

export const completeAccountDetails =
  (data: CompleteUserAccountDetailsRequest) =>
  async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: USER_REGISTRATION_DETAILS_REQUEST,
      });
      const response = await completeUserAccountDetails(data);
      const isSuccess = response.status === 204;
      if (isSuccess) {
        dispatch(getUserDetails() as any);
      }
      dispatch({
        type: isSuccess
          ? USER_REGISTRATION_DETAILS_SUCCESS
          : USER_REGISTRATION_DETAILS_ERROR,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTRATION_DETAILS_ERROR,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const getUserDetails =
  () =>
  async (dispatch: any): Promise<void> => {
    if (userSession.cognitoUsername) {
      try {
        //Check if we are doing a RSVP Login
        const pendingRSVPEventData = localStorage.getItem(RSVP_LOGIN_FLOW);
        if (!pendingRSVPEventData) {
          dispatch({
            type: USER_PROFILE_REQUEST,
          });
        }

        const response = await getUser(userSession.cognitoUsername);
        const isSuccess = response.status === 200;
        let userData: User = isSuccess ? response.data : null;

        if (isSuccess && !!userData?.id) {
          if (
            config.logRocketEnabled &&
            !!config.logRocketAppId &&
            !!LogRocket
          ) {
            LogRocket.identify(userSession.cognitoUsername, {
              name: userData.firstName + " " + userData.lastName,
              email: userData.email ?? "",
              userType: userData.userType,
              zipCode: userData.zip ?? "",
            });
          }
          if (config.sentryEnabled) {
            Sentry.setUser({
              id: userData.id,
              username: userSession.cognitoUsername,
            });
          }
        }

        if (pendingRSVPEventData && isSuccess) {
          const eventData = JSON.parse(pendingRSVPEventData);
          localStorage.removeItem(RSVP_LOGIN_FLOW);
          const rsvpResponse = await updateEventUserPreferences({
            eventId: eventData.eventId,
            userId: userData.id,
            preference: { isRSVPd: true },
          });

          if (rsvpResponse.isSuccess) {
            setTimeout(() => {
              customEventPublish(
                SHOW_RESULT_BANNER,
                `You've RSVP for this event!`,
              );
            }, 1000);
          }
        }

        dispatch({
          type: isSuccess ? USER_PROFILE_SUCCESS : USER_PROFILE_FAIL,
          payload: userData,
        });
      } catch (error) {
        dispatch({
          type: USER_PROFILE_FAIL,
          payload: `ERROR: ${error}`,
        });
      }
    }
  };

export const confirmCode =
  (userCode: string) =>
  async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: USER_CODE_VERIFICATION_REQUEST,
      });
      const response = await codeVerification(userCode);
      const isSuccess = response.status === 204;
      dispatch({
        type: isSuccess
          ? USER_CODE_VERIFICATION_SUCCESS
          : USER_CODE_VERIFICATION_ERROR,
        payload: {},
      });
    } catch (error) {
      dispatch({
        type: USER_CODE_VERIFICATION_ERROR,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const logout =
  () =>
  async (dispatch: any): Promise<void> => {
    try {
      return new Promise((resolve) => {
        Auth.signOut().then(() => {
          userSession.accessToken = null;
          setSentryContext();
          dispatch({
            type: USER_LOGOUT,
          });
          window.location.pathname = "/";
          resolve();
        });
      });
    } catch (error) {
      dispatch({
        type: USER_LOGOUT,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const sendVerificationEmail =
  (userId: string) =>
  async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: USER_SEND_VERIFICATION_EMAIL_REQUEST,
      });

      const response = await verifyEmail(userId);
      const isSuccess = response.status === 200;

      dispatch({
        type: isSuccess
          ? USER_SEND_VERIFICATION_EMAIL_SUCCESS
          : USER_SEND_VERIFICATION_EMAIL_ERROR,
        payload: {},
      });
    } catch (error) {
      dispatch({
        type: USER_SEND_VERIFICATION_EMAIL_ERROR,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const validateUserFirstLogin = async (
  data: UserLoginValidationRequest,
) => {
  try {
    const response = await userLoginValidation(data);
    return response.data;
  } catch (error: any) {
    return {
      alreadyVerified: error.response.data === "User account already verified",
    };
  }
};

export const setUserPassword = async (data: SetUserPasswordRequest) => {
  try {
    const response = await userPasswordSet(data);
    return { success: !!response.data.email };
  } catch (error: any) {
    return { success: false };
  }
};
