import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { useState } from "react";
import { useSelector } from "react-redux";

const MembershipDogTermsLogic = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;

  const onCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  return {
    termsAccepted,
    userName: `${userInfo?.firstName} ${userInfo?.lastName}`,
    onCheckboxChange,
  };
};

export default MembershipDogTermsLogic;
