import {
  Map,
  EventsList,
  ImageCarousel,
  MapPois,
  MapMini,
  Loader,
} from "@Components/index";
import "./Home.scss";
import { Navbar } from "@Layouts/index";
import HomeLogic from "./HomeLogic";
import { ArrowRight } from "@Components/icons";
import userSession from "@App/auth/userSession";
import { DEFAULT_MOBILE_MEDIA_QUERY } from "@App/constants/appConstants";
import { Link } from "react-router-dom";
//@ts-ignore
import themedStyles from "@Assets/styles/themedStyles.scss";

/* Leaving this commented because it will be added in the near future */
// const HomeSponsors = ({ sponsors }: HomeSponsorProps) => {
//   return (
//     <>
//       {sponsors.map((sponsor, index) => {
//         return (
//           <img
//             key={`sponsor_image${index}`}
//             className={"home-sponsors_image"}
//             src={sponsor.imageURL}
//             alt=""
//           />
//         );
//       })}
//     </>
//   );
// };

const Home = () => {
  const props = HomeLogic();
  if (!props.tenant) {
    return <Loader text={"Loading, please wait"} modal />;
  }
  const handleSelected = (url: string) => {
    window.location.href = url;
  };
  return (
    <Navbar>
      <div className={"home-container"}>
        <div className={"home-section-image"}>
          <ImageCarousel
            slides={props.tenant.cmsSettings.marketingBanner.map((banner) => {
              return {
                labelFirstElement: <div>{banner.title}</div>,
                labelSecondElement: <div>{banner.subTitle}</div>,
                image: banner.mediaURL,
              };
            })}
            showArrows={false}
            useDefaultLabel={true}
            customHeight={props.isMobileScreen ? "330px" : "596px"}
            swipeable={false}
          />
        </div>

        {props.upcomingEvents?.length > 0 && (
          <section className={"home-upcoming-events"}>
            <div className={"home-section-title"}>
              {userSession.cognitoUsername && props.hasFavOrRsvp
                ? "My Events"
                : "Upcoming Events"}
            </div>

            <EventsList
              items={
                userSession.cognitoUsername && props.hasFavOrRsvp
                  ? props.upcomingEvents.filter(
                      (e) =>
                        (e.user?.isFavorite ||
                          e.user?.isRSVPd ||
                          e.user?.isAttending) &&
                        e.eventType !== "Admin",
                    )
                  : props.upcomingEvents.filter((e) => e.eventType !== "Admin")
              }
              isCard={true}
              horizontalScroll={true}
              hideLoadingStatus
              notLoggedUser={!!!userSession.cognitoUsername}
            />
          </section>
        )}

        <section>
          {!!!userSession.cognitoUsername && (
            <Link className={"home-section-link"} to={"/locations"}>
              <div className={"home-section-title home-section-title-position"}>
                Explore the Community
                <ArrowRight color={themedStyles.defaultPrimaryColor} />
              </div>
            </Link>
          )}
          <div
            className={`${
              !!userSession.cognitoUsername ? "home-section-map" : ""
            } home-section-map-min`}>
            {!!userSession.cognitoUsername ? (
              <MapPois
                hasViewMore={
                  window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches
                }
              />
            ) : (
              <>
                <div className={"home-map"}>
                  <Map pois={[]} />
                </div>
                <div className={"home-map-min"}>
                  <MapMini pois={[]} defaultExapnded />
                </div>
              </>
            )}
          </div>
        </section>

        <section className={"home-places"}>
          <div
            className={"home-section-title home-section-title-position-right"}>
            {props.tenant.cmsSettings.explorableGallery.name}
          </div>
          <div className={"home-places-cards"}>
            {props.tenant.cmsSettings.explorableGallery.items?.map(
              (place, index) => {
                return (
                  <div
                    key={`place_item${index}`}
                    onClick={() => {
                      handleSelected(place.url);
                    }}>
                    <div
                      style={{
                        backgroundImage: `url(${place.imageURL})`,
                      }}
                      className={"home-places-card-image"}></div>
                    <div className={"home-places-card-text"}>{place.title}</div>
                  </div>
                );
              },
            )}
          </div>
        </section>
        {/* Leaving this commented because it will be added in the near future */}
        {/* <section className={"home-sponsors-wrapper"}>
          <div
            className={
              "home-section-title home-section-title-min home-section-title-position"
            }
          >
            Thank you to our sponsors
          </div>
          <div className={"home-sponsors"}>
            <HomeSponsors sponsors={props.tenant.cmsSettings.sponsors} />
          </div>

          <div className={"home-sponsors-min"}>
            <ImageCarousel
              customHeight={"150px"}
              showIndicators={false}
              showArrows={false}
              autoPlay={true}
              slides={props.tenant.cmsSettings.sponsors.map((sponsor) => {
                return { image: sponsor.imageURL };
              })}
              displaySingleImage={true}
            />
          </div>
        </section> */}
      </div>
    </Navbar>
  );
};

export default Home;
