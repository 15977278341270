import { baseServiceRequest } from "./baseService";
import moment from "moment";
import { format, parseISO } from "date-fns";
import {
  BookRecurringEventRequest,
  GetEventsProps,
  RecurringEventDatesRequest,
  UpdateEventUserPreferencesProps,
  UpdateRecurringEventUserPreferencesProps,
  ReserveFreeTicketRequest,
} from "./requests/events";
import { InitiatePaymentRequest } from "@Api/requests/payments";
import { AxiosError, AxiosResponse } from "axios";
import { EventPagedResponse } from "@App/models/event";

export const getEvents = async (
  data: GetEventsProps,
): Promise<AxiosResponse<EventPagedResponse, AxiosError>> => {
  const {
    page,
    pageSize,
    startDate,
    endDate,
    isIncludeAdminEvents = true,
    managedByIds,
  } = data;
  let params: GetEventsProps = {
    page,
    pageSize,
    startDate,
    isIncludeAdminEvents,
    managedByIds,
  };
  if (endDate) {
    params["endDate"] = endDate;
  }
  return await baseServiceRequest({
    path: `/event/Search`,
    type: "get",
    postData: data,
    useAuthHeader: true,
    params,
  });
};

export const getEventDetails = async (eventId: string) => {
  return await baseServiceRequest({
    path: `/event/${eventId}`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getEventCategories = async () => {
  return await baseServiceRequest({
    path: `/eventcategories`,
    type: "get",
  });
};

export const updateEventPreference = async (
  props: UpdateEventUserPreferencesProps,
) => {
  return await baseServiceRequest({
    path: `/event/${props.eventId}/user/${props.userId}`,
    type: "put",
    postData: props.preference,
    useAuthHeader: true,
  });
};

export const updateRecurringEventPreference = async (
  props: UpdateRecurringEventUserPreferencesProps,
) => {
  const startDateFormatted: string = moment(props.bodyData.startDate).format(
    "YYYY-MM-DD",
  );

  return await baseServiceRequest({
    path: `/event/${props.baseEventId}/${startDateFormatted}/user/${props.userId}`,
    type: "put",
    postData: props.bodyData,
    useAuthHeader: true,
  });
};

export const getEventAvailableTickets = async (eventId: string) => {
  return await baseServiceRequest({
    path: `/event/${eventId}/ticketsforPurchase`,
    type: "get",
    useAuthHeader: true,
  });
};

export const initiateEventTicketPurchase = async (
  data: InitiatePaymentRequest,
) => {
  return await baseServiceRequest({
    path: `/stripe/InitiatePaymentRequest`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const getRecurringEventDates = async (
  req: RecurringEventDatesRequest,
) => {
  const { eventId, startDate, endDate } = req;
  return await baseServiceRequest({
    path: `/event/${eventId}/recurringDates`,
    type: "get",
    useAuthHeader: true,
    params: { startDate, endDate },
  });
};

export const createRecurringEventForGivenDate = async (
  req: BookRecurringEventRequest,
) => {
  const { baseEventId, startDate, endDate, selectedDate } = req;
  return await baseServiceRequest({
    path: `/event/${baseEventId}/book/${format(
      parseISO(selectedDate),
      "yyyy-MM-dd",
    )}`,
    type: "post",
    useAuthHeader: true,
    postData: { startDate, endDate },
  });
};

//Cart/Event/ReserveFreeTickets

export const reserveFreeTickets = async (req: ReserveFreeTicketRequest) => {
  return await baseServiceRequest({
    path: `/cart/event/ReserveFreeTickets`,
    type: "post",
    useAuthHeader: true,
    postData: req,
  });
};
