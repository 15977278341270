import "./EventDetails.scss";
import { Navbar } from "@Layouts/index";
import EventDetailsLogic from "./EventDetailsLogic";
import {
  Breadcrumb,
  Button,
  ImageFilesCarousel,
  Loader,
  Tag,
  TicketPurchase,
  Modal,
  BackButton,
  WaiversModal,
} from "@Components/index";
import { Heart } from "@Components/icons";
import { handleNotLoggedUserLogin } from "@App/store/actions/eventActions";
import { formatCurrency, handleExternalUrl } from "@Utils/utils";

const EventDetails = () => {
  const {
    waiverItems,
    currentEvent,
    userInfo,
    ticketingCtaLabel,
    showCTAs,

    isUserLogged,
    isCTAButtonDisabled,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,

    isTicketPurchaseModalOpen,
    setIsTicketPurchaseModalOpen,
    isWaiversModalOpen,
    setIsWaiversModalOpen,

    getEventDate,
    getEventTime,
    getEventCategories,

    goBack,
    updateEventPreference,
    handleTicketPurchase,
    handleWaiversClose,
    handleCTAButton,
    getEventStartAndEndDates,
    isRecurring,
  } = EventDetailsLogic();
  return (
    <Navbar>
      {!currentEvent ? (
        <Loader modal text={"Loading event details"} />
      ) : (
        <div className={"event-details-container"}>
          <div className={"event-details-breadcrumb"}>
            <Breadcrumb
              initialSection={{ text: "Home", path: "/" }}
              currentSectionName={currentEvent.title}
            />
          </div>
          <div className={"event-details-navigation-min"}>
            <BackButton goBack={goBack} />
          </div>

          <div className={"event-details-text event-details-detail-wrapper"}>
            <div className={"event-details-name"}>{currentEvent.title}</div>
            <div className={"event-details-date"}>{getEventDate()}</div>
            <div>{getEventTime()}</div>
            <div className="event-details-location">
              {currentEvent.venue || currentEvent.address?.address1}
            </div>
            {currentEvent.eventType !== "Admin" && (
              <div className={"event-details-crousel"}>
                <ImageFilesCarousel files={currentEvent.files || []} />
              </div>
            )}
            {currentEvent.eventType !== "Admin" &&
              isUserLogged &&
              !currentEvent.user?.isRSVPd && (
                <div className={"event-details-fav"}>
                  <button
                    className={"event-details-fav-button"}
                    disabled={isFavPreferenceProcessing}
                    style={{ marginTop: isFavPreferenceProcessing ? 5 : 0 }}
                    data-cy="event-details-heart"
                    onClick={async () => {
                      await updateEventPreference(
                        "isFavorite",
                        !currentEvent.user?.isFavorite,
                      );
                    }}>
                    {isFavPreferenceProcessing ? (
                      <Loader
                        color={"silver"}
                        horizontallyCentered
                        width="20"
                        height="10"
                      />
                    ) : (
                      <Heart isActive={currentEvent.user?.isFavorite} />
                    )}
                  </button>
                </div>
              )}
            <div className={"event-details-bottom-content"}>
              <div className={"event-details-bottom-content-left"}>
                <div
                  className={"event-details-description"}
                  dangerouslySetInnerHTML={{
                    __html: currentEvent.description ?? "",
                  }}></div>
                <div className={"event-details-categories"}>
                  {getEventCategories().map((eventCategory, index) => {
                    return (
                      <div
                        key={`event_category_${index}`}
                        className={"event-details-category"}>
                        <Tag text={eventCategory} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`event-details-bottom-content-right ${
                  isUserLogged
                    ? "event-details-bottom-content-right-logged"
                    : ""
                }`}
                style={{
                  justifyContent: currentEvent.user?.isRSVPd
                    ? "flex-end"
                    : "space-between",
                }}>
                <div
                  className={`event-details-top-actions ${
                    isUserLogged && currentEvent.user?.isRSVPd
                      ? "event-details-top-actions-rsvpd"
                      : ""
                  }`}>
                  {/* TODO: Sharing will be enabled after launch. Leaving the code commented now */}
                  {/* {currentEvent.eventType !== "Admin" && isUserLogged && (
                    <button
                      className={"event-details-share"}
                      onClick={() => {
                        alert("TBD Share api");
                      }}
                    >
                      <Share />
                    </button>
                  )} */}
                </div>
                {currentEvent.address ? (
                  <div className={"event-details-location-bottom"}>
                    <p>Location</p>
                    <p className="event-details-location-bottom-text">
                      {`${currentEvent.address.address1} ${currentEvent.address.city}, ${currentEvent.address.state} ${currentEvent.address.zip}`}
                    </p>
                  </div>
                ) : null}
                {/* Hide default buttons if there are overrides */}
                {showCTAs &&
                  ((currentEvent.callsToAction?.length ?? 0) > 0 ? (
                    currentEvent.callsToAction?.map((cta, index) => {
                      return (
                        <Button
                          key={`cta_action${index}`}
                          text={cta.displayText}
                          onClick={() => handleExternalUrl(cta.url)}
                          style={{ marginTop: index > 0 ? 10 : 0 }}
                        />
                      );
                    })
                  ) : (
                    <>
                      {/* Not recurring RSVPing */}
                      {currentEvent.eventType !== "Admin" &&
                        currentEvent.isRsvpEnabled &&
                        Array.isArray(currentEvent.ticketsPrices) &&
                        !currentEvent.ticketsPrices.length &&
                        !currentEvent.recurring?.isRecurring && (
                          <div className={"event-details-rsvp"}>
                            {!currentEvent.user?.isRSVPd ? (
                              isRsvpPreferenceProcessing ? (
                                <Loader color={"silver"} horizontallyCentered />
                              ) : (
                                <Button
                                  isLoading={isRsvpPreferenceProcessing}
                                  text={isUserLogged ? "RSVP" : "Login to RSVP"}
                                  onClick={async () => {
                                    if (isUserLogged) {
                                      if (currentEvent?.waiverIds?.length) {
                                        setIsWaiversModalOpen(true);
                                      } else {
                                        await updateEventPreference(
                                          "isRSVPd",
                                          !currentEvent.user?.isRSVPd,
                                        );
                                      }
                                    } else {
                                      handleNotLoggedUserLogin(currentEvent);
                                    }
                                  }}
                                />
                              )
                            ) : isRsvpPreferenceProcessing ? (
                              <Loader color={"silver"} horizontallyCentered />
                            ) : (
                              <Button
                                isLoading={isRsvpPreferenceProcessing}
                                text={"Attending"}
                                isSecondary={true}
                                onClick={async () => {
                                  if (isUserLogged) {
                                    await updateEventPreference(
                                      "isRSVPd",
                                      false,
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        )}
                      {/* Recurring RSVPing */}
                      {currentEvent.eventType !== "Admin" &&
                        currentEvent.isRsvpEnabled &&
                        Array.isArray(currentEvent.ticketsPrices) &&
                        !currentEvent.ticketsPrices?.length &&
                        currentEvent.recurring?.isRecurring && (
                          <div className={"event-details-rsvp"}>
                            {!currentEvent.user?.isRSVPd ? (
                              isRsvpPreferenceProcessing ? (
                                <Loader color={"silver"} horizontallyCentered />
                              ) : (
                                <Button
                                  isLoading={isRsvpPreferenceProcessing}
                                  text={
                                    isUserLogged
                                      ? "See dates & RSVP"
                                      : "Login to RSVP"
                                  }
                                  onClick={() => {
                                    if (
                                      isUserLogged &&
                                      currentEvent?.waiverIds?.length
                                    ) {
                                      setIsWaiversModalOpen(true);
                                    } else if (
                                      isUserLogged &&
                                      !currentEvent?.waiverIds?.length
                                    ) {
                                      setIsTicketPurchaseModalOpen(true);
                                    } else {
                                      handleNotLoggedUserLogin(currentEvent);
                                    }
                                  }}
                                />
                              )
                            ) : isRsvpPreferenceProcessing ? (
                              <Loader color={"silver"} horizontallyCentered />
                            ) : // Virtual clone: Is base event (recurring.isRecurring) and has query param (isRecurring) show the RSVP button
                            isRecurring ? (
                              <Button
                                isLoading={isRsvpPreferenceProcessing}
                                text={
                                  isUserLogged
                                    ? "See dates & RSVP"
                                    : "Login to RSVP"
                                }
                                onClick={() => {
                                  if (
                                    isUserLogged &&
                                    currentEvent?.waiverIds?.length
                                  ) {
                                    setIsWaiversModalOpen(true);
                                  } else if (
                                    isUserLogged &&
                                    !currentEvent?.waiverIds?.length
                                  ) {
                                    setIsTicketPurchaseModalOpen(true);
                                  } else {
                                    handleNotLoggedUserLogin(currentEvent);
                                  }
                                }}
                              />
                            ) : (
                              <Button
                                isLoading={isRsvpPreferenceProcessing}
                                text={"Attending"}
                                isSecondary={true}
                                onClick={async () => {
                                  if (isUserLogged) {
                                    await updateEventPreference(
                                      "isRSVPd",
                                      false,
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        )}
                      {/* Ticketed events */}
                      {currentEvent.eventType !== "Admin" &&
                        Array.isArray(currentEvent.ticketsPrices) &&
                        !!currentEvent.ticketsPrices.length && (
                          <div className={"event-details-tickets"}>
                            {currentEvent.user?.isAttending ? (
                              <>
                                {isRecurring ? (
                                  <>
                                    <div className="event-details-tickets-card">
                                      <h3 className="event-details-tickets-card__title">
                                        Tickets Prices
                                      </h3>
                                      <div className="event-details-tickets-card__list">
                                        {currentEvent?.ticketsPrices?.map(
                                          ({ name, price, id }) => (
                                            <div
                                              className="event-details-tickets-card__row"
                                              key={id}>
                                              <div className="event-details-tickets__type">
                                                {name}
                                              </div>
                                              <div className="event-details-tickets__price">{`${formatCurrency(
                                                price,
                                              )}`}</div>
                                            </div>
                                          ),
                                        )}
                                      </div>
                                    </div>
                                    <Button
                                      text={ticketingCtaLabel}
                                      onClick={handleCTAButton}
                                      isDisabled={isCTAButtonDisabled}
                                      dataCy="event-details-purchase-tickets-btn"
                                    />
                                  </>
                                ) : (
                                  <Button
                                    text={"Attending"}
                                    isSecondary={true}
                                    onClick={() => {}}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <div className="event-details-tickets-card">
                                  <h3 className="event-details-tickets-card__title">
                                    Tickets Prices
                                  </h3>
                                  <div className="event-details-tickets-card__list">
                                    {currentEvent?.ticketsPrices?.map(
                                      ({ name, price, id }) => (
                                        <div
                                          className="event-details-tickets-card__row"
                                          key={id}>
                                          <div className="event-details-tickets__type">
                                            {name}
                                          </div>
                                          <div className="event-details-tickets__price">{`${formatCurrency(
                                            price,
                                          )}`}</div>
                                        </div>
                                      ),
                                    )}
                                  </div>
                                </div>
                                <Button
                                  text={ticketingCtaLabel}
                                  onClick={handleCTAButton}
                                  isDisabled={isCTAButtonDisabled}
                                  dataCy="event-details-purchase-tickets-btn"
                                />
                              </>
                            )}
                          </div>
                        )}
                    </>
                  ))}
              </div>
            </div>
          </div>

          <Modal isVisible={isTicketPurchaseModalOpen}>
            <TicketPurchase
              eventData={currentEvent}
              initialSelectedDate={
                getEventStartAndEndDates(currentEvent).startDate
              }
              onClose={(eventId) => handleTicketPurchase(eventId)}
            />
          </Modal>

          <WaiversModal
            userInfo={{
              firstname: userInfo?.firstName ?? "",
              lastname: userInfo?.lastName ?? "",
            }}
            waivers={waiverItems ?? []}
            isVisible={isWaiversModalOpen}
            onContinue={handleWaiversClose}
            ctaText="Continue to Book"
          />
        </div>
      )}
    </Navbar>
  );
};

export default EventDetails;
