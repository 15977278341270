import { getMembershipDetailsById } from "@App/api/memberships";
import { MEMBERSHIP_DOGS, SHOW_LOGIN_MODAL } from "@App/constants/appConstants";
import { QUERY_KEY_MEMBERSHIP_DETAILS } from "@App/constants/queryKeyConstants";
import { MembershipType } from "@App/models/membership";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { customEventPublish } from "@Utils/utils";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const MembershipDetailsLogic = () => {
  //TODO: Remove any from detail when we have the BE, use a type (model)
  const [details, setDetails] = useState<MembershipType>();
  const [isFav, setIsFav] = useState(false);
  const [isFavProcessing, setIsFavProcessing] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [ctaText, setCtaText] = useState("Log In to Apply");
  const [dogMembershipModalVisible, setDogMembershipModalVisible] =
    useState(false);
  const storeData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = storeData;
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [QUERY_KEY_MEMBERSHIP_DETAILS, id],
    () => getMembershipDetailsById(id!),
    { enabled: !!id },
  );
  useEffect(() => {
    if (data) {
      setDetails(data.data);
    }
  }, [id, data, userInfo?.firstName]);

  const membershipModalClose = (isCancel: boolean) => {
    if (isCancel) {
      localStorage.removeItem(MEMBERSHIP_DOGS);
    }
    setDogMembershipModalVisible(false);
  };

  useEffect(() => {
    if (!isUserLogged && !!userInfo?.firstName) {
      setIsUserLogged(true);
      setCtaText("Proceed to form");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const goBack = () => {
    navigate(-1);
  };

  const updateFav = () => {
    //TODO: connect to BE
    setIsFavProcessing(true);
    setTimeout(() => {
      setIsFav(!isFav);
      setIsFavProcessing(false);
    }, 2000);
  };

  const handleNotLoggedUserLogin = () => {
    customEventPublish(SHOW_LOGIN_MODAL, {});
  };

  const onApplyClick = () => {
    if (details?.isInternal) {
      setDogMembershipModalVisible(true);
    } else {
      //TODO: BE and Designs needed for other memberships different than Dog
      window.open(
        details?.externalMembershipURL,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };

  return {
    details,
    isFav,
    isFavProcessing,
    isUserLogged,
    ctaText,
    dogMembershipModalVisible,
    isLoading,
    goBack,
    updateFav,
    onApplyClick,
    membershipModalClose,
    userInfo,
    handleNotLoggedUserLogin,
  };
};

export default MembershipDetailsLogic;
