import { useSelector } from "react-redux";
import { RootState } from "@Store/store";
import { UserState } from "@App/store/reducers/userReducer";
import { useEffect } from "react";
import {
  getAmenitiesDetails,
  updateAmenityPreference,
} from "@App/api/amenities";
import { useQuery, QueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { QUERY_KEY_USER_AMENITIES } from "@App/constants/queryKeyConstants";
import {
  customEventPublish,
  sendMobileViewModePostMessage,
} from "@Utils/utils";
import { SHOW_LOGIN_MODAL } from "@App/constants/appConstants";

const AmenitiesDetailLogic = () => {
  const { amenityId } = useParams<{ amenityId: string }>();
  const queryClient = new QueryClient();
  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
    };
  });
  const { storeUser } = storeData;

  const navigate = useNavigate();

  useEffect(() => {
    if (amenityId) {
      try {
        queryClient.invalidateQueries([QUERY_KEY_USER_AMENITIES, amenityId!]);
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUser, amenityId]);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY_USER_AMENITIES, amenityId!],
    queryFn: () => getAmenitiesDetails(amenityId!),
    staleTime: 0,
  });

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoading });
  }, [isLoading]);

  const { mutate: updateFavorite, isLoading: isFavLoading } = useMutation(
    () =>
      updateAmenityPreference({
        amenityId: amenityId!,
        userId: storeUser?.userInfo?.id!,
        preference: { isFavorite: !data?.data.user?.isFavorite },
      }),
    {
      onSuccess: () => {
        if (data?.data) {
          //(User is always null until the user Favs or attends, etc).
          data.data.user = {
            id: storeUser?.userInfo?.id!,
            isFavorite: !data?.data.user?.isFavorite ?? false,
          };
        }
        queryClient.setQueryData([QUERY_KEY_USER_AMENITIES, amenityId!], data);
        return queryClient.invalidateQueries([
          QUERY_KEY_USER_AMENITIES,
          amenityId!,
        ]);
      },
    },
  );

  const goBack = () => {
    navigate(-1);
  };

  const handleBooking = (communityAssetId: string) => {
    navigate(`/venues/venues-details/${communityAssetId}`);
  };

  const handleNotLoggedUserBook = () => {
    customEventPublish(SHOW_LOGIN_MODAL, {});
  };

  const location = data?.data.address?.address1
    ? `${data?.data.address?.address1} ${data?.data.address?.city}, ${data?.data.address?.state} ${data?.data.address?.zip}`
    : "";

  return {
    data,
    isLoading,
    isFavLoading,
    updateFavorite,
    location,
    goBack,
    handleBooking,
    handleNotLoggedUserBook,
  };
};

export default AmenitiesDetailLogic;
