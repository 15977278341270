import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TicketPurchaseRowsProps } from "./TicketPurchaseRowsPropTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createRecurringEventForGivenDate,
  updateRecurringEventPreference,
} from "@App/api/event";
import {
  UpdateRecurringEventUserPreferencesProps,
  BookRecurringEventRequest,
} from "@App/api/requests/events";
import { customEventPublish } from "@Utils/utils";
import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";
import { QUERY_KEY_EVENT_DETAILS } from "@App/constants/queryKeyConstants";
import { useSelector } from "react-redux";
import { RootState } from "@App/store/store";
import { UserState } from "@App/store/reducers/userReducer";

const TicketPurchaseRowsLogic = (props: TicketPurchaseRowsProps) => {
  const [selectedTime, setSelectedTime] = useState(-1);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const { userInfo } = userData;
  const createEventMutation = useMutation({
    mutationFn: (req: BookRecurringEventRequest) =>
      createRecurringEventForGivenDate(req),
    onSuccess: (res) => {
      const eventId = res?.data?.id;
      props.onClose();
      if (!eventId) return;
      navigate(`/calendar/event-details/tickets?id=${eventId}`);
    },
  });
  const updateEventMutation = useMutation({
    mutationFn: (req: UpdateRecurringEventUserPreferencesProps) =>
      updateRecurringEventPreference(req),
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEY_EVENT_DETAILS]);
      const eventId = res?.data?.id;
      customEventPublish(SHOW_RESULT_BANNER, `You've RSVP for this event!`);
      props.onClose(eventId);
    },
  });

  const onBookClick = async (indexSelected?: number) => {
    const { id, baseEventId, startDate, endDate, eventType } =
      props.recurringDates[
        indexSelected !== undefined &&
        Number.isInteger(indexSelected) &&
        indexSelected >= 0
          ? indexSelected
          : selectedTime
      ];
    if (id && eventType === "Ticketed") {
      props.onClose();
      navigate(`/calendar/event-details/tickets?id=${id}`);
    } else if (eventType === "Ticketed") {
      await createEventMutation.mutateAsync({
        baseEventId,
        startDate,
        endDate,
        selectedDate: startDate,
      });
    } else if (eventType === "General") {
      await updateEventMutation.mutateAsync({
        baseEventId,
        userId: userInfo?.id!,
        bodyData: {
          id,
          startDate,
          endDate,
          eventUser: {
            isRSVPd: true,
          },
        },
      });
    }
  };

  return {
    selectedTime,
    setSelectedTime,
    onBookClick,
  };
};

export default TicketPurchaseRowsLogic;
