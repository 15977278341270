import {
  EVENTS_REQUEST_GET,
  EVENTS_REQUEST_SUCCESS,
  EVENTS_REQUEST_FAIL,
  EVENT_CATEGORIES_REQUEST_GET,
  EVENT_CATEGORIES_REQUEST_SUCCESS,
  EVENT_CATEGORIES_REQUEST_FAIL,
  UPCOMING_EVENTS_REQUEST_GET,
  UPCOMING_EVENTS_REQUEST_FAIL,
  UPCOMING_EVENTS_REQUEST_SUCCESS,
} from "@App/constants/eventConstants";
import {
  getEventCategories,
  getEventDetails,
  getEvents,
  updateEventPreference,
  updateRecurringEventPreference,
} from "@Api/event";
import {
  UpdateEventUserPreferencesProps,
  UpdateRecurringEventUserPreferencesProps,
} from "@App/api/requests/events";
import { Event } from "@App/models/event";
import { EventCategory } from "@App/models/eventCategory";
import { RSVP_LOGIN_FLOW, SHOW_LOGIN_MODAL } from "@App/constants/appConstants";
import { customEventPublish } from "@Utils/utils";
import moment from "moment";
import { config } from "@App/config/config";

type GetEventsData = {
  page: number;
  pageSize: number;
  startDate: string;
  endDate?: string;
  isRefresh?: boolean;
  isUpcoming?: boolean;
  isIncludeAdminEvents?: boolean;
  managedById?: string;
};
export const getEventsData =
  ({
    page,
    pageSize,
    startDate,
    endDate,
    isRefresh,
    isUpcoming,
    isIncludeAdminEvents,
    managedById,
  }: GetEventsData) =>
  async (dispatch: any): Promise<void> => {
    let resultTypeSuccess = EVENTS_REQUEST_SUCCESS;
    let resultTypeFail = EVENTS_REQUEST_FAIL;
    try {
      if (!isRefresh) {
        const getType = isUpcoming
          ? UPCOMING_EVENTS_REQUEST_GET
          : EVENTS_REQUEST_GET;
        dispatch({
          type: getType,
        });
      }
      const response = await getEvents({
        page,
        pageSize,
        startDate,
        endDate,
        isIncludeAdminEvents,
        ...(managedById && { managedByIds: [managedById] }),
      });
      const isSuccess = response.status === 200;
      let eventsData: Event[] | null = isSuccess ? response.data.items : null;

      if (isSuccess) {
        if (isUpcoming) {
          resultTypeSuccess = UPCOMING_EVENTS_REQUEST_SUCCESS;
        }
      } else {
        if (isUpcoming) {
          resultTypeFail = UPCOMING_EVENTS_REQUEST_FAIL;
        }
      }
      dispatch({
        type: isSuccess ? resultTypeSuccess : resultTypeFail,
        payload: eventsData,
      });
    } catch (error) {
      dispatch({
        type: resultTypeFail,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const getEventDetailsData = async (
  eventId: string,
): Promise<Event | null> => {
  try {
    const response = await getEventDetails(eventId);
    return response.data;
  } catch (error) {
    console.log("ERROR: ", error);
    return null;
  }
};

export const getEventCategoriesData =
  () =>
  async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: EVENT_CATEGORIES_REQUEST_GET,
      });

      const response = await getEventCategories();
      const isSuccess = response.status === 200;
      let eventCategories: EventCategory[] = isSuccess ? response.data : null;

      dispatch({
        type: isSuccess
          ? EVENT_CATEGORIES_REQUEST_SUCCESS
          : EVENT_CATEGORIES_REQUEST_FAIL,
        payload: eventCategories,
      });
    } catch (error) {
      dispatch({
        type: EVENT_CATEGORIES_REQUEST_FAIL,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const updateEventUserPreferences = async (
  args: UpdateEventUserPreferencesProps,
): Promise<any> => {
  try {
    const response = await updateEventPreference(args);
    return { isSuccess: response.status >= 200 && response.status < 300 };
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

export const updateRecurringEventUserPreferences = async (
  args: UpdateRecurringEventUserPreferencesProps,
): Promise<any> => {
  try {
    const response = await updateRecurringEventPreference(args);
    return { isSuccess: response.status >= 200 && response.status < 300 };
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

export const handleNotLoggedUserLogin = (
  event: Event,
  addEventToStorage = false,
) => {
  if (addEventToStorage) {
    localStorage.setItem(
      RSVP_LOGIN_FLOW,
      JSON.stringify({ eventId: event.id, eventDate: event.startDate }),
    );
  }
  customEventPublish(SHOW_LOGIN_MODAL, { cleanRsvpFlow: false });
};

export const getEventsList =
  ({
    startDate,
    endDate,
    isRefresh,
    isUpcoming,
    isIncludeAdminEvents,
    managedById,
  }: {
    startDate: Date;
    endDate: Date;
    isRefresh?: boolean;
    isUpcoming?: boolean;
    isIncludeAdminEvents?: boolean;
    managedById?: string;
  }) =>
  async (dispatch: any): Promise<void> => {
    const isCurrentMonth =
      moment().format("YYYY/MM") === moment(startDate).format("YYYY/MM");
    let requestEndDate = moment()
      .add(config.upcomingEventsEndDateMonths, "M")
      .format("YYYY-MM-DD");
    let initialMonthDate =
      isCurrentMonth || isUpcoming ? new Date().getDate() : "01";

    if (!isUpcoming) {
      const formattedMonth = moment(startDate).format("MM");
      const year = endDate.getFullYear();
      const lastDayOfMonth = new Date(year, Number(formattedMonth), 0);
      requestEndDate = `${year}-${formattedMonth}-${lastDayOfMonth.getDate()}T23:59:59`;
    }
    const reqStartDate = isUpcoming ? new Date() : new Date(startDate);
    reqStartDate.setDate(+initialMonthDate);
    if (!isCurrentMonth) {
      reqStartDate.setHours(0);
      reqStartDate.setMinutes(0);
      reqStartDate.setSeconds(0);
    }

    dispatch(
      getEventsData({
        page: 1,
        // This large page size is a shim until we can implement pagination with infinite scroll
        // It's used indiscriminately for all event requests and should be replaced ASAP
        pageSize: 400,
        startDate: moment(reqStartDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
        endDate: requestEndDate,
        isRefresh: isRefresh,
        isUpcoming,
        isIncludeAdminEvents,
        managedById,
      }),
    ) as any;
  };

export const getUpcomingEvents =
  ({
    isIncludeAdminEvents = false,
    managedById,
  }: {
    isIncludeAdminEvents?: boolean;
    managedById?: string;
  }) =>
  async (dispatch: any): Promise<void> => {
    const date = new Date();
    dispatch(
      getEventsList({
        startDate: date,
        endDate: date,
        isUpcoming: true,
        isIncludeAdminEvents,
        managedById,
      }) as any,
    );
  };
