import {
  Button,
  Loader,
  MembershipDogForm,
  Modal,
  Stepper,
} from "@Components/index";
import "./MembershipModal.scss";
import { MembershipModalProps } from "./MembershipModalPropTypes";
import MembershipModalLogic from "./MembershipModalLogic";
import MembershipDogTerms from "./membership-dog-terms/MembershipDogTerms";

const MembershipModal = (componentProps: MembershipModalProps) => {
  const props = MembershipModalLogic(componentProps);
  return (
    <Modal isVisible={props.isVisible}>
      <div className={"membership-modal"}>
        <div className={"membership-modal-top"}>
          <Button
            type={"close"}
            onClick={props.onClose}
            style={{ padding: 0, color: "#000" }}
          />
          <Stepper steps={[1, 2]} currentStep={props.currentStep} />
        </div>
        {props.currentStep === 1 ? (
          <MembershipDogForm
            title={componentProps.membershipDetails?.title}
            onContinue={(userMembershipId, userId) =>
              props.onContinue(userMembershipId, userId)
            }
            details={componentProps.membershipDetails}
          />
        ) : (
          <MembershipDogTerms
            waiver={componentProps.membershipDetails?.waivers[0] ?? ""}
            onProceedToPayment={() => props.onProceedToPayment()}
          />
        )}
      </div>
      {props.isProcessing && <Loader text="Processing, please wait" modal />}
    </Modal>
  );
};

export default MembershipModal;
