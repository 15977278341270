import {
  EVENTS_REQUEST_GET,
  EVENTS_REQUEST_SUCCESS,
  EVENTS_REQUEST_FAIL,
  EVENT_CATEGORIES_REQUEST_GET,
  EVENT_CATEGORIES_REQUEST_SUCCESS,
  EVENT_CATEGORIES_REQUEST_FAIL,
  UPCOMING_EVENTS_REQUEST_GET,
  UPCOMING_EVENTS_REQUEST_SUCCESS,
  UPCOMING_EVENTS_REQUEST_FAIL,
} from "@App/constants/eventConstants";
import { Event } from "@App/models/event";
import { EventCategory } from "@App/models/eventCategory";

export interface EventsBaseState {
  loading?: boolean;
  error?: string;
}

export interface EventsState extends EventsBaseState {
  events: Event[];
}

export interface UpcomingEventsState extends EventsBaseState {
  upcomingEvents: Event[];
}

export interface EventCategoriesState extends EventsBaseState {
  eventCategories: EventCategory[];
}

interface EventAction {
  type: string;
  payload?: any;
}

interface EventCategoriesAction {
  type: string;
  payload: EventCategory[];
}

export const eventsDataReducer = (
  state: EventsState = { events: [] },
  action: EventAction,
) => {
  let currentState;
  switch (action.type) {
    case EVENTS_REQUEST_GET:
      currentState = { loading: true };
      break;
    case EVENTS_REQUEST_SUCCESS:
      currentState = { loading: false, events: action.payload };
      break;
    case EVENTS_REQUEST_FAIL:
      currentState = { loading: false, error: action.payload };
      break;
    default:
      currentState = state;
      break;
  }
  return currentState;
};

export const upcomingEventsDataReducer = (
  state: UpcomingEventsState = { upcomingEvents: [] },
  action: EventAction,
) => {
  let currentState;
  switch (action.type) {
    case UPCOMING_EVENTS_REQUEST_GET:
      currentState = { loading: true };
      break;
    case UPCOMING_EVENTS_REQUEST_SUCCESS:
      const upcomingEvents = action.payload;
      currentState = { loading: false, upcomingEvents: upcomingEvents };
      break;
    case UPCOMING_EVENTS_REQUEST_FAIL:
      currentState = { loading: false, upcomingEvents: action.payload };
      break;

    default:
      currentState = state;
      break;
  }
  return currentState;
};

export const eventCategoriesDataReducer = (
  state: EventCategoriesState = { eventCategories: [] },
  action: EventCategoriesAction,
) => {
  let currentState;
  switch (action.type) {
    case EVENT_CATEGORIES_REQUEST_GET:
      currentState = { loading: true, eventCategories: [] };
      break;
    case EVENT_CATEGORIES_REQUEST_SUCCESS:
      currentState = { loading: false, eventCategories: action.payload };
      break;
    case EVENT_CATEGORIES_REQUEST_FAIL:
      currentState = {
        loading: false,
        error: "Error loading categories",
        eventCategories: [],
      };
      break;
    default:
      currentState = state;
      break;
  }

  return currentState;
};
