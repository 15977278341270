import "./CalendarSelect.scss";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Event } from "@App/models/event";
import { Chevron } from "@Components/icons";
import { monthName } from "@Utils/utils";

interface CalendarSelectProps {
  selected?: Date | null;
  events?: Event[];
  availableDates?: string[];
  isVenuesView?: boolean;
  onMonthChange?: (month: Date) => void;
  onChange: (date: any) => void;
  dataCy?: string;
}

const CalendarSelect = ({
  selected,
  events,
  availableDates,
  isVenuesView,
  onMonthChange,
  onChange,
  dataCy,
}: CalendarSelectProps) => {
  const renderDayContents = (day: number, date: Date) => {
    const tooltipText = `Tooltip for date: ${date}`;
    const isValidMonthDay = moment(date)
      .utc()
      .isAfter(moment().subtract(1, "d"));
    const calendarItem = events
      ? events?.find(
          (event) =>
            date >= moment(event.startDate).startOf("day").toDate() &&
            date <= moment(event.endDate).startOf("day").toDate(),
        )
      : availableDates?.find(
          (availableDate) =>
            moment(availableDate).get("D") === date.getDate() &&
            moment(availableDate).get("M") === date.getMonth(),
        );

    return (
      <div
        className={`calendar-select-custom-day ${
          !isValidMonthDay ? "calendar-select-custom-day-invalid" : ""
        }`}
        title={tooltipText}>
        {day}
        {calendarItem && (
          <div className={"calendar-select-custom-day-marker"}></div>
        )}
      </div>
    );
  };

  return (
    <div className={"calendar-select"} data-cy={dataCy}>
      <DatePicker
        onChange={(value) => {
          onChange(value);
        }}
        onMonthChange={(date) => {
          onMonthChange && onMonthChange(date);
        }}
        inline
        calendarClassName={`calendar-select-calendar ${
          isVenuesView ? "calendar-select-calendar__venues" : ""
        }`}
        renderDayContents={renderDayContents}
        selected={selected}
        disabledKeyboardNavigation={true}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <div className={"calendar-select-custom-header"}>
            <div
              className={"calendar-select-custom-header-label"}>{`${monthName({
              month: moment(date).utc().get("M"),
            })} ${moment(date).get("year")}`}</div>
            <div className={"calendar-select-custom-header-month-nav-wrapper"}>
              <button
                className={"calendar-select-custom-header-month-nav"}
                onClick={decreaseMonth}>
                <Chevron position={"left"} size={30} />
              </button>
              <button
                className={"calendar-select-custom-header-month-nav"}
                data-cy="calendar-select-month-forward-nav"
                onClick={increaseMonth}>
                <Chevron position={"right"} size={30} />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CalendarSelect;
