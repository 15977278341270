import { Chevron } from "@Components/icons";
import { Navbar } from "@Layouts/index";
import * as Accordion from "@radix-ui/react-accordion";
import { BackButton, Breadcrumb } from "@Components/index";
import "./CmsContactUs.scss";
import CmsDetailLogic from "../cms-detail/CmsDetailLogic";
import { HtmlWrapper } from "@Components/html-wrapper/HtmlWrapper";

const CmsContactUs = () => {
  const { detailPageData, goBack, isMobileEmbed, buildFileUrl, tenant } =
    CmsDetailLogic({
      hardCodedId: "contact-us",
    });

  if (!detailPageData) {
    return <></>;
  }

  return (
    <Navbar>
      <div className="contact-us">
        <div className={"contact-us__breadcrumb"}>
          <Breadcrumb
            initialSection={{ text: "Home", path: "/" }}
            currentSectionName="Contact Us"
            hasIdentificator
          />
          {!isMobileEmbed && (
            <div className="contact-us__back">
              <BackButton goBack={goBack} />
            </div>
          )}
        </div>
        {detailPageData.contentHtml && (
          <div className="contact-us__detail">
            <div className="contact-us__detail__left">
              <div className="contact-us__detail__left__title">
                {detailPageData.title}
              </div>
              <div className="contact-us__detail__left__description">
                <HtmlWrapper htmlContent={detailPageData.contentHtml} />
              </div>
            </div>
            {detailPageData.rightContentImageName && (
              <div
                className="contact-us__detail__right"
                style={{
                  backgroundImage: `url(${buildFileUrl(
                    tenant?.awsSettings.assetStorageBaseURL,
                    detailPageData.rightContentImageName,
                  )})`,
                }}
              />
            )}
          </div>
        )}
        <div className="contact-us__more">
          <div className="contact-us__more__title">
            {detailPageData.accordionTitle}
          </div>
          <Accordion.Root
            className="contact-us__more__accordion"
            type="multiple">
            {detailPageData.contactEntities?.map((entity, index) => {
              return (
                <Accordion.Item
                  className="contact-us__more__accordion__item"
                  value={`item-entity-${index}`}
                  key={`item-entity-${index}`}>
                  <Accordion.Header>
                    <label className="contact-us__more__accordion__item__header">
                      {entity.title}
                    </label>
                    <Accordion.Trigger className="contact-us__more__accordion__item__header__trigger">
                      <Chevron position="down" color={"#334150"} aria-hidden />
                      <Chevron position="up" color={"#334150"} aria-hidden />
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content className="contact-us__more__accordion__item__content">
                    <div>
                      {entity.comment && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: entity.comment,
                          }}></div>
                      )}

                      {entity.phone && (
                        <p>
                          Phone:{" "}
                          <a href={`tel:${entity.phone}`}>{entity.phone}</a>
                        </p>
                      )}
                      {entity.email && (
                        <p>
                          Email:{" "}
                          <a href={`mailto:${entity.email}`}>{entity.email}</a>
                        </p>
                      )}
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion.Root>
        </div>
      </div>
    </Navbar>
  );
};

export default CmsContactUs;
