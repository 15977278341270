import { Event } from "@App/models/event";
import { getEventAvailableTickets, getEventDetails } from "@App/api/event";
import { useQuery } from "@tanstack/react-query";
import {
  QUERY_KEY_EVENT_DETAILS,
  QUERY_KEY_EVENT_TICKETS_DATA,
} from "@App/constants/queryKeyConstants";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { sendMobileViewModePostMessage } from "@Utils/utils";

const EventTicketPurchaseLogic = () => {
  const [params] = useSearchParams();
  const eventId = params.get("id");
  // TODO: Update when events return more than one date
  const { data: currentEvent, isLoading: isLoadingEvent } = useQuery(
    [QUERY_KEY_EVENT_DETAILS, eventId],
    () =>
      getEventDetails(eventId!).then(({ data }) => {
        data.ticketingDates = [
          { startDate: data.startDate, endDate: data.endDate },
        ];
        return data as Event;
      }),
    { enabled: !!eventId },
  );
  const { data: availableTickets, isLoading: isLoadingTicketsData } = useQuery(
    [QUERY_KEY_EVENT_TICKETS_DATA, eventId],
    () =>
      getEventAvailableTickets(eventId!).then(
        (res) => res.data?.availableTickets,
      ),
    {
      enabled: !!eventId,
    },
  );

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: isLoadingEvent });
  }, [isLoadingEvent]);

  return {
    currentEvent,
    availableTickets,
    isLoadingTicketsData,
    isLoadingEvent,
  };
};

export default EventTicketPurchaseLogic;
