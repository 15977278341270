import { getCommunityGroupById } from "@App/api/communityGroups";
import { QUERY_KEY_USER_COMMUNITY_GROUPS } from "@App/constants/queryKeyConstants";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const ClubsAndSocialsDetailsLogic = () => {
  const { communityGroupId } = useParams<{ communityGroupId: string }>();
  const queryClient = new QueryClient();
  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
    };
  });
  const { storeUser } = storeData;

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY_USER_COMMUNITY_GROUPS, communityGroupId!],
    queryFn: () => getCommunityGroupById(communityGroupId!),
    staleTime: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (communityGroupId) {
      try {
        queryClient.invalidateQueries([
          QUERY_KEY_USER_COMMUNITY_GROUPS,
          communityGroupId!,
        ]);
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUser, communityGroupId]);

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: false });
  }, []);

  const updateFavorite = () => {
    alert("TBD");
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    data,
    isLoading,
    updateFavorite,
    goBack,
  };
};

export default ClubsAndSocialsDetailsLogic;
