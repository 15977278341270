import { AxiosError, AxiosResponse } from "axios";
import { baseServiceRequest } from "@Api/baseService";
import { TenantFooter } from "@App/models/tenant/TenantFooter";
import { NavbarResponse } from "@App/models/NavbarItem";
import { TenantTheme } from "@App/models/TenantTheme";

export const getTenantFooter = async (): Promise<
  AxiosResponse<TenantFooter, AxiosError>
> => {
  return await baseServiceRequest({
    path: `/tenant/footer-detail`,
    type: "get",
  });
};

export const getTenantNavbarItems = async () => {
  return await baseServiceRequest<NavbarResponse>({
    path: "/tenant/navbar",
    type: "get",
  });
};

export const getTenantTheme = async () => {
  return await baseServiceRequest<TenantTheme>({
    path: `/tenant/style-config`,
    type: "get",
  });
};
