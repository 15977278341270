import { Information } from "@Components/icons";
import "./ActionBanner.scss";
import { ActionBannerBannerProps } from "./ActionBannerPropTypes";

const ActionBanner = (componentProps: ActionBannerBannerProps) => {
  return (
    <div
      className={"action-banner"}
      style={{
        ...(componentProps.backgroundColor && {
          backgroundColor: componentProps.backgroundColor,
        }),
      }}
      data-cy="action-banner">
      <div className={"action-banner-info"}>
        {componentProps.customIcon ? (
          componentProps.customIcon
        ) : (
          <Information
            {...(componentProps.fontColor && {
              color: componentProps.fontColor,
            })}
          />
        )}
        <label
          className={`action-banner-text ${
            !componentProps.noUnderline && "action-banner-text-underline"
          } ${componentProps.onClick && "action-banner-text-link"}`}
          onClick={() => {
            componentProps.onClick && componentProps.onClick();
          }}
          style={{
            ...(componentProps.fontColor && {
              color: componentProps.fontColor,
            }),
          }}
          data-cy="action-banner-text">
          {componentProps.text}
        </label>
      </div>
      {componentProps.actionLinkText && (
        <label
          className={`action-banner-text action-banner-text-underline action-banner-text-link`}
          onClick={() => {
            componentProps.actionClick && componentProps.actionClick();
          }}
          style={{
            ...(componentProps.fontColor && {
              color: componentProps.fontColor,
            }),
          }}
          data-cy="action-banner-link">
          {componentProps.actionLinkText}
        </label>
      )}
    </div>
  );
};

export default ActionBanner;
