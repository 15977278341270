import { useEffect, useRef, useState } from "react";
import { Event } from "@App/models/event";
import moment from "moment";
import {
  getEventsList,
  updateEventUserPreferences,
  updateRecurringEventUserPreferences,
} from "@App/store/actions/eventActions";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";
import { customEventPublish } from "@Utils/utils";
import { useLocation } from "react-router-dom";
import { useTenantConfig } from "@App/hooks";

const EventItemLogic = (event: Event) => {
  const [eventItem, setEventItem] = useState(event);
  const eventStartDate = moment(event.startDate);
  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] =
    useState(false);
  const [isRsvpPreferenceProcessing, setIsRsvpPreferenceProcessing] =
    useState(false);
  const [currentRoutePath] = useState(useLocation().pathname);

  const prevEvent = useRef<Event>();

  const dispatch = useDispatch();

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const tenantConfig = useTenantConfig();
  const { userInfo } = userData;

  const refreshEvents = () => {
    const date = new Date(event.startDate);

    //Get all events for the selected month
    dispatch(
      getEventsList({
        startDate: date,
        endDate: date,
        isRefresh: true,
      }) as any,
    );

    //Get all the updated events (Upcoming) to get the updated Favs and RSVPs
    dispatch(
      getEventsList({
        startDate: date,
        endDate: date,
        isRefresh: true,
        isUpcoming: true,
      }) as any,
    );
  };

  useEffect(() => {
    setEventItem(event);

    if (
      !prevEvent.current ||
      (prevEvent.current && isFavPreferenceProcessing)
    ) {
      setIsFavPreferenceProcessing(false);
      setIsRsvpPreferenceProcessing(false);
    }
    prevEvent.current = event;
  }, [userInfo, event, isFavPreferenceProcessing]);

  const updateEventPreference = (
    preferenceName: string,
    preferenceValue: boolean,
  ) => {
    if (userInfo) {
      if (preferenceName === "isRSVPd") {
        setIsRsvpPreferenceProcessing(true);
      } else {
        setIsFavPreferenceProcessing(true);
      }

      if (!!event.id) {
        updateEventUserPreferences({
          eventId: event.id,
          userId: userInfo.id,
          preference: { [preferenceName]: preferenceValue },
        }).then((response) => {
          if (response.isSuccess) {
            if (preferenceValue) {
              customEventPublish(
                SHOW_RESULT_BANNER,
                `You've ${
                  preferenceName === "isRSVPd" ? "RSVP'd" : "favorited"
                } ${eventItem.title}!`,
              );
            }

            // This works but causes the list to rerender really janky sometimes
            refreshEvents();
          }
        });
      } else {
        updateRecurringEventUserPreferences({
          baseEventId: event.baseEventId || "",
          userId: userInfo.id,
          bodyData: {
            id: event.id,
            startDate: event.startDate,
            endDate: event.endDate,
            eventUser: {
              [preferenceName]: preferenceValue,
            },
          },
        }).then((response) => {
          if (response.isSuccess) {
            if (preferenceValue) {
              customEventPublish(
                SHOW_RESULT_BANNER,
                `You've ${
                  preferenceName === "isRSVPd" ? "RSVP'd" : "favorited"
                } ${eventItem.title}!`,
              );
            }

            // Optimistic update would be nice, but is a bit messy here.
            // Should do some refactoring first.
            refreshEvents();
          }
        });
      }
    }
  };

  const getEventLink = () => {
    const isRecurrentEvent = eventItem.id === null;
    return `/calendar/event-details?id=${
      isRecurrentEvent ? eventItem.baseEventId : eventItem.id
    }${
      isRecurrentEvent
        ? `&eventRecurrenceStartDate=${eventItem.startDate}&eventRecurrenceEndDate=${eventItem.endDate}`
        : ""
    }`;
  };

  return {
    eventItem,
    eventStartDate,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,
    areCommunityUserAccountsDisabled:
      tenantConfig?.featureToggles?.areCommunityUserAccountsDisabled,
    currentRoutePath,
    updateEventPreference,
    getEventLink,
  };
};

export default EventItemLogic;
