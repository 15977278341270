import {
  BackButton,
  Breadcrumb,
  Button,
  GenericModal,
  Loader,
  Tag,
  ImageFilesCarousel,
} from "@Components/index";
import "./GenericDetails.scss";
import { GenericDetailsProps } from "./GenericDetailsPropTypes";
import { Heart } from "@Components/icons";
import GenericDetailsLogic from "./GenericDetailsLogic";
import { formatCurrency, handleExternalUrl } from "@Utils/utils";

const GenericDetails = (componentProps: GenericDetailsProps) => {
  const props = GenericDetailsLogic(componentProps);
  return (
    <div
      className={`generic-details ${
        props.isMobileEmbed ? "generic-details-embed" : ""
      }`}>
      <div className={"generic-details-breadcrumb"}>
        <Breadcrumb
          initialSection={{ text: "Home", path: "/" }}
          currentSectionName={props.details.title}
          hasIdentificator
          ignorePaths={componentProps.breadcrumbIgnorePaths}
        />
      </div>
      {!props.isMobileEmbed && (
        <div className={"generic-details-back"}>
          <BackButton goBack={props.goBack} />
        </div>
      )}
      <div className={"generic-details-content"}>
        <div className={"generic-details-content-type"}>
          {props.details.type}
        </div>
        <div className={"generic-details-content-title"}>
          {props.details.title}
        </div>
        <div className={"generic-details-content-dates"}>
          {props.details.openDates}
        </div>
        <div className={"generic-details-content-images"}>
          <ImageFilesCarousel
            showIndicators={
              props.details.files && props.details.files.length > 1
            }
            files={props.details.files}
          />
        </div>
        {props.isUserLogged && componentProps.onFavClick && (
          <div className={"generic-details-content-bottom-right-fav"}>
            <button
              className={"generic-details-content-bottom-right-fav-button"}
              disabled={props.isFavProcessing}
              onClick={componentProps.onFavClick}>
              {props.isFavProcessing ? (
                <Loader
                  color={"silver"}
                  horizontallyCentered
                  width="20"
                  height="10"
                />
              ) : (
                <Heart isActive={props.isFav} />
              )}
            </button>
          </div>
        )}
        <div className={"generic-details-content-bottom"}>
          <div className={"generic-details-content-bottom-left"}>
            <div
              className={"generic-details-content-description"}
              dangerouslySetInnerHTML={{
                __html: props.details.description,
              }}
            />
            {props.details.location && (
              <div className={"generic-details-content-description-location"}>
                <span
                  className={
                    "generic-details-content-description-location-title"
                  }>
                  Location:
                </span>
                <p>{props.details.location}</p>
              </div>
            )}
            {componentProps.detailsAction ? componentProps.detailsAction : null}
            {props.details.tags && (
              <div className={"generic-details-content-description-tags"}>
                {props.details.tags.map((tag: string, index: number) => {
                  return <Tag key={`tag_item_${index}`} text={tag} />;
                })}
              </div>
            )}
            {props.details?.rulesandRegulation && (
              <div
                className={"generic-details-content-description-rules"}
                onClick={() => {
                  props.setShowReservationRules(true);
                }}>
                Rules & Regulations
              </div>
            )}
          </div>
          <div className={"generic-details-content-bottom-right"}>
            {componentProps.pricingData && (
              <div className="generic-details-content-bottom-subscription">
                <div className="generic-details-content-bottom-subscription__title">
                  {componentProps.pricingData.title}
                </div>
                <div className="generic-details-content-bottom-subscription__list">
                  {props.details.rates?.map((rate: any, i: any) => (
                    <div
                      className="generic-details-content-bottom-subscription__row"
                      key={i}>
                      <div>{rate.name}</div>
                      <div>{`${formatCurrency(rate.price)}`}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {(componentProps.callsToAction?.length ?? 0) > 0
              ? componentProps.callsToAction?.map((cta, index) => {
                  return (
                    <Button
                      key={`cta_action${index}`}
                      text={cta.displayText}
                      onClick={() => handleExternalUrl(cta.url)}
                      style={{ marginTop: index > 0 ? 10 : 0 }}
                    />
                  );
                })
              : componentProps.primaryButton && (
                  <Button
                    text={props.CTAButtonText}
                    onClick={props.handleCTAButton}
                    isDisabled={props.isCTAButtonDisabled}
                  />
                )}
          </div>
        </div>
      </div>
      {props.details?.rulesandRegulation && (
        <GenericModal
          onClose={() => props.setShowReservationRules(false)}
          isVisible={props.showReservationRules}
          children={
            <div
              dangerouslySetInnerHTML={{
                __html: props.details.rulesandRegulation,
              }}></div>
          }
          ctaText={"Close"}
          onCtaClick={() => props.setShowReservationRules(false)}
        />
      )}
    </div>
  );
};

export default GenericDetails;
