import { useEffect, useState } from "react";
import { PoiDetailProps } from "./PoiDetailPropTypes";
import { Poi } from "@App/models/poi";
import {
  getEventsList,
  updateEventUserPreferences,
} from "@App/store/actions/eventActions";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { updateAmenityPreference } from "@App/api/amenities";

const PoiDetailLogic = (props: PoiDetailProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [poiDetail, setPoiDetail] = useState<Poi | null>(props.poi);
  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] =
    useState(false);
  const [isFav, setIsFav] = useState(props.poi?.user?.isFavorite ?? false);
  const isEvent = props.poi?.poiCategory === "Event";
  const dispatch = useDispatch();

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const { userInfo } = userData;
  useEffect(() => {
    setPoiDetail(props.poi);
  }, [props.poi]);

  // Not used?
  const updateEventPreference = (preferenceValue: boolean) => {
    setIsFavPreferenceProcessing(true);
    if (userInfo?.id && props.poi) {
      updateEventUserPreferences({
        eventId: props.poi.id,
        userId: userInfo.id,
        preference: { isFavorite: preferenceValue },
      }).then((response) => {
        setIsFavPreferenceProcessing(false);
        if (response.isSuccess) {
          setIsFav(preferenceValue);
          dispatch(
            getEventsList({
              startDate: new Date(),
              endDate: new Date(),
              isRefresh: true,
              isUpcoming: true,
            }) as any,
          );
          props.onRefreshData && props.onRefreshData();
        }
      });
    }
  };

  const updateCommunityAssetPreference = (preferenceValue: boolean) => {
    setIsFavPreferenceProcessing(true);
    if (userInfo?.id && props.poi) {
      updateAmenityPreference({
        amenityId: props.poi.id,
        userId: userInfo.id,
        preference: { isFavorite: preferenceValue },
      }).then((response) => {
        setIsFavPreferenceProcessing(false);
        if (response.status === 204) {
          setIsFav(preferenceValue);
          props.onRefreshData && props.onRefreshData();
        }
      });
    }
  };

  const onFavClick = () => {
    if (isEvent) {
      updateEventPreference(!isFav);
    } else {
      updateCommunityAssetPreference(!isFav);
    }
  };

  return {
    isVisible,
    poiDetail,
    isFavPreferenceProcessing,
    isFav,
    userInfo,
    setIsVisible,
    onFavClick,
  };
};

export default PoiDetailLogic;
