import { TenantTheme } from "@App/models/TenantTheme";
import { baseServiceRequest } from "../baseService";
// import { TENANT_ID_PROP } from "@App/constants/appConstants";

export const getTenantTheme = async () => {
  return await baseServiceRequest<TenantTheme>({
    path: `/tenant/style-config`,
    type: "get",
  });
};

// Use this during local development. Then update the API mongo scripts to use the new tenant theme
// export const getTenantTheme = async () => {
//   const tenantId = localStorage.getItem(TENANT_ID_PROP);
//   const theme = fetch(`/tenant-theme-${tenantId}.json`).then(
//     (res) => res.json() as Promise<{ data: TenantTheme }>,
//   );

//   return theme;
// };
