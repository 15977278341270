import { useEffect, useState } from "react";

import {
  PaymentContext,
  PaymentTicket,
  PaymentsProps,
} from "./PaymentsPropTypes";
import { EVENT_TICKET_DATE } from "@App/constants/appConstants";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { RootState } from "@App/store/store";
import { UserState } from "@App/store/reducers/userReducer";
import {
  formatCurrency,
  isSameDay,
  monthName,
  sendMobileViewModePostMessage,
} from "@Utils/utils";
import moment, { Moment } from "moment";
import {
  initiateEventTicketPurchase,
  reserveFreeTickets,
} from "@App/api/event";
import { useNavigate } from "react-router-dom";
import userSession from "@App/auth/userSession";
import { format, parseISO } from "date-fns";
import { initiateVenueBookingPayment } from "@App/api/amenities";
import { useQuery } from "@tanstack/react-query";
import { tenantConfigGet } from "@App/api/general";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { Tenant } from "@App/models/tenant";
import { getAddOnById } from "@App/api/addOns";
import { AddOn } from "@App/models/addOn";
import { Prompt } from "@App/models/prompt";
import { Waiver } from "@App/models/waiver";
import { getPromptById } from "@App/api/prompts";
import { addWaiver, getWaiverById } from "@App/api/waiver";
import { UserType } from "@App/models/types";
import { InitiatePaymentRequest } from "@Api/requests/payments";
import { ReserveFreeTicketRequest } from "@Api/requests/events";

const PaymentsLogic = (props: PaymentsProps) => {
  const [bookingRequestData, setBookingRequestData] = useState<any>(null);
  const [ticketRequestData, setTicketRequestData] = useState<any>(null);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [purchaseStep, setPurchaseStep] = useState(props.initialStep ?? 0);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);

  const [currentPaymentDetails] = useState<PaymentContext | null>(
    props.paymentData ?? null,
  );

  const [ticketAmount, setTicketAmount] = useState(props.items);
  const [discountCode, setDiscountCode] = useState("");
  const [isTicketPurchaseModalOpen, setIsTicketPurchaseModalOpen] =
    useState(false);

  const [addOnItems, setAddOnItems] = useState<AddOn[] | null>(null);
  const [isLoadingAddOns, setIsLoadingAddOns] = useState(false);
  const [selectedAddOnIds, setSelectedAddOnIds] = useState<string[]>([]);

  const [promptsItems, setPromptItems] = useState<Prompt[] | null>(null);
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(false);
  const [promptsResponses, setPromptsResponses] = useState<any>(null);
  const [showPromptsModal, setShowPromptsModal] = useState(false);

  const [isLoadingWaivers, setIsLoadingWaivers] = useState(false);
  const [waiverItems, setWaiverItems] = useState<Waiver[] | null>(null);
  const [waiverIds, setWaiverIds] = useState<string[] | undefined>([]);
  const [showWaiversModal, setShowWaiversModal] = useState(false);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [isWaiverProcessing, setIsWaiverProcessing] = useState(false);
  const [noPaymentRequired, setNoPaymentRequired] = useState(false);

  // UserType blocking state
  const [page0CTAButtonText, setPage0CTAButtonText] = useState<
    string | undefined
  >();
  const [isPage0CTAButtonDisabled, setIsPage0CTAButtonDisabled] =
    useState(false);

  const navigate = useNavigate();

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = userData;

  useEffect(() => {
    if (userData.userInfo?.firstName) {
      setIsUserLogged(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  useEffect(() => {
    setShowPromptsModal(!isLoadingPrompts && !!promptsItems);
  }, [isLoadingPrompts, promptsItems]);

  useEffect(() => {
    setShowWaiversModal(!isLoadingWaivers && !!waiverItems);
  }, [isLoadingWaivers, waiverItems]);

  // UserType blocking logic
  useEffect(() => {
    if (!userInfo) {
      return;
    }

    if (
      [UserType.Initiated, UserType.ResidentPending].includes(userInfo.userType)
    ) {
      setPage0CTAButtonText("Must Verify Residency to Continue");
      setIsPage0CTAButtonDisabled(true);
      return;
    }

    if (props.paymentData?.type === "booking") {
      setPage0CTAButtonText("Book");
      setIsPage0CTAButtonDisabled(false);
      return;
    }

    setPage0CTAButtonText("Proceed");
    setIsPage0CTAButtonDisabled(false);
  }, [userInfo, props.paymentData]);

  useEffect(() => {
    if (props.availableTickets && props.availableTickets?.length !== 0) {
      setTicketAmount(
        props.availableTickets.map((ticket: PaymentTicket) => ({
          id: ticket.id,
          ticketType: ticket.name,
          amount: 0,
        })),
      );
    }
    if (props.paymentData) {
      if (props.paymentData.type === "membership") {
        const stripeKey = props.membership.publishableKey;
        setStripePromise(loadStripe(stripeKey));
      }
      if (props.paymentData.addOnIds?.length) {
        setIsLoadingAddOns(true);
        const getAddOnsAsync = async () => {
          let addOns: AddOn[] = [];
          for await (let addOn of props.paymentData?.addOnIds!) {
            const response = await getAddOnById(addOn.addOnsId);
            addOns.push(response.data);
          }
          return addOns;
        };
        getAddOnsAsync().then((addOns) => {
          setAddOnItems(addOns);
          setIsLoadingAddOns(false);
        });
      }

      if (props.paymentData.waiverIds?.length) {
        setIsLoadingWaivers(true);
        const getWaiversAsync = async () => {
          let waivers: Waiver[] = [];
          for await (let waiverId of props.paymentData?.waiverIds!) {
            const response = await getWaiverById(waiverId);
            waivers.push(response.data);
          }
          return waivers;
        };
        getWaiversAsync().then((waivers) => {
          setWaiverItems(waivers);
          setWaiverIds(waivers?.map((waiver) => waiver.id));
          setIsLoadingWaivers(false);
        });
      }

      if (props.paymentData.promptIds?.length) {
        setIsLoadingPrompts(true);
        const getPromptsAsync = async () => {
          let prompts: Prompt[] = [];
          for await (let prompt of props.paymentData?.promptIds!) {
            const response = await getPromptById(prompt.promptId);
            prompts.push(response.data);
          }
          return prompts;
        };
        getPromptsAsync().then((prompts) => {
          setPromptItems(prompts);
          if (!props.paymentData?.waiverIds?.length) {
            setIsLoadingPrompts(false);
          }
        });
      }
    }

    sendMobileViewModePostMessage({ isLoading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTotalPrice = () => {
    if (props.paymentData?.type === "membership") {
      return formatCurrency(props.membership?.cart.totalPrice);
    }
    if (props.paymentData?.type === "booking") {
      return formatCurrency(bookingRequestData?.cart.totalPrice);
    }
    if (props.paymentData?.type === "ticket") {
      return formatCurrency(ticketRequestData?.cart?.totalPrice);
    }

    if (!currentPaymentDetails) return formatCurrency(0);
  };

  const getTicketTypeTotalPrice = (name: string) => {
    if (!currentPaymentDetails) return 0;
    let totalPrice = 0;
    ticketAmount?.forEach((ticket) => {
      const ticketPrice = currentPaymentDetails.ticketsPrices?.find(
        (ticketPrice) => ticketPrice.name === ticket.ticketType,
      );
      if (ticketPrice && ticket.ticketType === name) {
        totalPrice += ticketPrice.price * ticket.amount;
      }
    });
    return formatCurrency(totalPrice);
  };

  const getDatetimeValue = (date: Moment) => {
    const month = monthName({
      month: date.get("M"),
      abbreviation: true,
    });
    return `${date.format(`ddd D [${month}]`)}`;
  };

  const getEventDate = (single = false) => {
    const storageTicketDate = localStorage.getItem(EVENT_TICKET_DATE);
    const storageTicketDateObject = storageTicketDate
      ? JSON.parse(storageTicketDate)
      : currentPaymentDetails;
    const ticketStartDate = storageTicketDateObject?.startDate;
    const ticketEndDate = storageTicketDateObject?.endDate;
    if (storageTicketDateObject && !single) {
      return `${getDatetimeValue(moment(ticketStartDate))}${
        !isSameDay(ticketStartDate, ticketEndDate)
          ? ` - ${getDatetimeValue(moment(ticketEndDate))}`
          : ""
      }`;
    }
    if (storageTicketDateObject && single) {
      return `${getDatetimeValue(
        moment((currentPaymentDetails as any)?.startDate),
      )}`;
    }
  };

  const getEventTime = () => {
    const storageTicketDate = localStorage.getItem(EVENT_TICKET_DATE);
    const storageTicketDateObject = storageTicketDate
      ? JSON.parse(storageTicketDate)
      : currentPaymentDetails;

    let startTime: string = "";
    let endTime: string = "";
    if (storageTicketDateObject) {
      startTime = moment(storageTicketDateObject.startDate)
        .format("h:mm A")
        .replace(":00", "");
      endTime = moment(storageTicketDateObject.endDate)
        .format("h:mm A")
        .replace(":00", "");
    }
    if (startTime === endTime) {
      return `${startTime}`;
    } else {
      return `${startTime} - ${endTime}`;
    }
  };

  const getBookingDate = () => {
    return `${format(
      parseISO(props.bookingDate.selectedDate!),
      "eee do MMMM yyyy",
    )}`;
  };

  const getBookingTime = () => {
    const startTime = props.bookingDate.startTime;
    const endTime = props.bookingDate.endTime;
    const bookingTime = `${format(parseISO(startTime!), "h:mm aaa")} - ${format(
      parseISO(endTime!),
      "h:mm aaa",
    )}`;
    return bookingTime;
  };

  const onAddOnClick = (addOnId: string, isSelected: boolean) => {
    if (isSelected && selectedAddOnIds?.indexOf(addOnId) === -1) {
      let selectedItems = selectedAddOnIds;
      selectedItems.push(addOnId);
      setSelectedAddOnIds(selectedItems);
    } else if (!isSelected) {
      setSelectedAddOnIds(selectedAddOnIds?.filter((s) => s !== addOnId));
    }
  };

  const handlePromptsClose = (promptResponses: any) => {
    setPromptsResponses(promptResponses);
    setShowPromptsModal(false);
  };

  const handleWaiverAcceptance = async () => {
    try {
      // If type is ticket/event, we need to link the waiver to the event using the addWaiver API
      // If type is booking, the link to the waiver is done in the initiateVenueBookingPayment API
      if (props.paymentData?.type === "ticket") {
        setIsWaiverProcessing(true);
        const waiverReq = [];
        for (let waiverId of waiverIds!) {
          const req = addWaiver({
            eventId: props.paymentData?.id,
            waiverId,
            userId: userInfo!.id,
          });
          waiverReq.push(req);
        }
        if (waiverReq.length) {
          await Promise.all(waiverReq);
        }
        setIsWaiverProcessing(false);
      }
      setShowWaiversModal(false);
      if (props.paymentData?.promptIds?.length && promptsItems) {
        setShowPromptsModal(true);
        setIsLoadingPrompts(false);
      }
    } catch (error) {
      alert("There was an error with your request.");
      navigate(-1);
    }
  };

  const handlePurchaseStep = async () => {
    try {
      setIsProcessingRequest(true);
      if (props.paymentData?.type === "booking") {
        const addOns = selectedAddOnIds.map((id) => {
          return { addOnsId: id };
        });
        const AssetReservationRequests = [
          {
            AssetId: props.paymentData?.id,
            RateId: props.bookingDate.rateId,
            StartTime: props.bookingDate.startTime,
            EndTime: props.bookingDate.endTime,
            ...(props.paymentData.spaceId && {
              SpaceId: props.paymentData.spaceId,
            }),
            ...(selectedAddOnIds?.length && {
              addOns: addOns,
            }),
            ...(promptsResponses?.length && {
              prompts: promptsResponses,
            }),
            ...(waiverIds?.length && {
              waiverIds: waiverIds,
            }),
            reservationType: "Individual",
          },
        ];
        const bookingRequestRes = await initiateVenueBookingPayment({
          AssetReservationRequests,
        });
        setBookingRequestData(bookingRequestRes.data);
        const stripeKey = bookingRequestRes.data.publishableKey;
        setStripePromise(loadStripe(stripeKey));
      }
      if (props.paymentData?.type === "ticket") {
        const tickets = ticketAmount
          ?.filter((ticket) => ticket.amount > 0)
          .map((ticket) => ({
            ticketTypeId: ticket.id!,
            quantity: ticket.amount!,
            eventId: currentPaymentDetails?.id!,
          }))!;

        // check if all tickets are free to switch between free and paid ticket flow
        const noPaymentRequired = tickets?.every((ticket) => {
          const ticketPrice = currentPaymentDetails?.ticketsPrices?.find(
            (ticketPrice) => ticketPrice.id === ticket.ticketTypeId,
          );
          return ticketPrice?.price === 0;
        });
        setNoPaymentRequired(noPaymentRequired);

        // Create the request object
        const eventTicketRequest:
          | InitiatePaymentRequest
          | ReserveFreeTicketRequest = {
          ticketRequests: tickets,
          prompts: promptsResponses,
        };

        // If all tickets are free, we use the reserveFreeTickets API
        if (noPaymentRequired) {
          await reserveFreeTickets(eventTicketRequest);
          setPurchaseStep(purchaseStep + 2);
          return;
        }

        // If there is at least one paid ticket, we use the initiateEventTicketPurchase API
        const ticketRequestsRes =
          await initiateEventTicketPurchase(eventTicketRequest);
        setTicketRequestData(ticketRequestsRes.data);
        const stripeKey = ticketRequestsRes.data.publishableKey;
        setStripePromise(loadStripe(stripeKey));
      }
      setPurchaseStep(purchaseStep + 1);
    } catch (error) {
      alert("There was an error with your request.");
      navigate(-1);
    }
  };

  const handlePaymentSuccess = () => {
    setPurchaseStep(purchaseStep + 1);
  };

  const increaseTicketAmount = (ticketId: string) => {
    const ticketAmounts =
      ticketAmount?.map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            amount: ticket.amount + 1,
          };
        }
        return ticket;
      }) ?? null;
    setTicketAmount(ticketAmounts);
  };
  const decreaseTicketAmount = (ticketId: string) => {
    const ticketAmounts =
      ticketAmount?.map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            amount: ticket.amount - 1,
          };
        }
        return ticket;
      }) ?? null;
    setTicketAmount(ticketAmounts);
  };

  const handleTicketPurchase = () => {
    setIsTicketPurchaseModalOpen(false);
  };

  const goBack = () => {
    const { type } = props.paymentData!;
    //If ticketPurchasePage (step) is 2, then we take the user back to the Event Deatils because the ticket has been already paid.
    if (purchaseStep === 1) {
      setPurchaseStep(0);
    } else if (type === "booking" && props.goBack) {
      props.goBack();
    } else {
      navigate(-1);
    }
  };

  const getClientSecret = () => {
    const { type } = props.paymentData!;
    switch (type) {
      case "membership":
        return props.membership.clientSecret;

      case "ticket":
        return ticketRequestData.clientSecret;

      case "booking":
        return bookingRequestData.clientSecret;

      default:
        return ticketRequestData.clientSecret;
    }
  };

  return {
    isUserLogged,
    purchaseStep,
    currentPaymentDetails,
    ticketAmount,
    userInfo,
    discountCode,
    isTicketPurchaseModalOpen,
    handlePurchaseStep,
    calculateTotalPrice,
    getTicketTypeTotalPrice,
    getEventDate,
    getEventTime,
    getBookingDate,
    getBookingTime,
    setDiscountCode,
    increaseTicketAmount,
    decreaseTicketAmount,
    setIsTicketPurchaseModalOpen,
    handleTicketPurchase,
    ticketRequestData,
    stripePromise,
    goBack,
    handlePaymentSuccess,
    redirectUrl,
    isMobileEmbed,
    getClientSecret,
    bookingRequestData,
    tenantName: tenantConfig?.name,
    isLoadingAddOns,
    addOnItems,
    onAddOnClick,
    promptsItems,
    isLoadingPrompts,
    handlePromptsClose,
    showPromptsModal,
    waiverItems,
    showWaiversModal,
    handleWaiverAcceptance,
    isProcessingRequest,
    isWaiverProcessing,
    noPaymentRequired,
    page0CTAButtonText,
    isPage0CTAButtonDisabled,
  };
};

export default PaymentsLogic;
