import { Navbar } from "@Layouts/index";
import "./AmenitiesDetail.scss";
import { Loader, GenericDetails } from "@Components/index";
import AmenitiesDetailLogic from "./AmenitiesDetailLogic";

const AmenitiesDetail = () => {
  const {
    data,
    isLoading,
    isFavLoading,
    updateFavorite,
    location,
    goBack,
    handleBooking,
    handleNotLoggedUserBook,
  } = AmenitiesDetailLogic();

  return (
    <Navbar>
      {isLoading && !data ? (
        <Loader
          text={"Loading, please wait"}
          color={"#000"}
          fontColor={"#000"}
          horizontallyCentered
          padding={"100px 20px"}
        />
      ) : (
        <GenericDetails
          details={{
            id: data?.data.id,
            title: data?.data.title,
            description: data?.data.description,
            location: location,
            tags: ["Amenities"],
            files: data?.data.files,
            user: { isFavorite: data?.data.user?.isFavorite },
            rulesandRegulation: data?.data.financeRules?.rulesandRegulation,
          }}
          goBack={goBack}
          {...(data?.data.canBeBooked && {
            primaryButton: {
              loggedText: "Book Now",
              notLoggedText: "Login to Book",
              loggedAction: () => handleBooking(data.data.id),
              notLoggedAction: () => handleNotLoggedUserBook(),
            },
          })}
          onFavClick={updateFavorite}
          isFavProcessing={isFavLoading}
          callsToAction={data?.data.callsToAction}
        />
      )}
    </Navbar>
  );
};

export default AmenitiesDetail;
