import "./EventsList.scss";
import React from "react";
import { EventItem, Loader } from "@Components/index";
import { Chevron } from "@Components/icons/index";
import { EventsListProps } from "./EventsListPropTypes";
import EventsListLogic from "./EventsListLogic";
import moment from "moment";

const EventsList = (props: EventsListProps) => {
  const SCROLL_AMOUNT = 500;
  const {
    filteredEvents,
    eventsRowType,
    ignoreAttending,
    showEventsLeftScrollButton,
    showEventsRightScrollButton,
    setShowEventsLeftScrollButton,
  } = EventsListLogic(props);
  const noEvents = !filteredEvents || filteredEvents.length === 0;
  if (!props.hideLoadingStatus && props.isProcessing) {
    return <Loader color={"#000"} />;
  } else if (!props.hideLoadingStatus && !props.isProcessing && noEvents) {
    return props.filter ? (
      <div>{`NO ${props.filter.toUpperCase()} (for the selected month)`}</div>
    ) : (
      <div className={"event-list-header"}>
        <div
          className={
            "event-list-header-no-events"
          }>{`no events for the selected ${
          props.dateFilter ? "date" : "month"
        }`}</div>
        {props.onShowAllClick && props.dateFilter && (
          <button
            className={"event-list-show-all-events"}
            onClick={() => {
              props.onShowAllClick && props.onShowAllClick();
            }}>
            reset calendar
          </button>
        )}
      </div>
    );
  } else if (noEvents && props.hideLoadingStatus) {
    return null;
  }
  let dateLabels: string[] = [];
  return (
    <React.Fragment>
      {(props.titleIcon || props.title) && (
        <div className={"events-list-title"}>
          {props.titleIcon && (
            <div className={"events-list-title-icon"}>{props.titleIcon}</div>
          )}
          {props.title && (
            <span className={"events-list-title-text"}>{props.title}</span>
          )}
        </div>
      )}
      <div
        className={`event-list`}
        {...(props.maxHeight && { style: { maxHeight: props.maxHeight } })}>
        <div className={"event-list-items"}>
          {props.isCard && (
            <div className={"event-list-items-scroll-control"}>
              <button
                className={`event-list-items-scroll-control-button event-list-items-scroll-control-button-left ${
                  showEventsLeftScrollButton
                    ? ""
                    : "event-list-items-scroll-control-button-hidden"
                }
                `}
                onClick={() => {
                  const el = document.getElementById(
                    `events-list-wrapper-${eventsRowType}`,
                  );
                  if (el) {
                    el.scrollLeft -= 500;
                    setShowEventsLeftScrollButton(
                      el.scrollLeft - SCROLL_AMOUNT > 0,
                    );
                  }
                }}>
                <Chevron position={"left"} size={15} color={"#323232"} />
              </button>
            </div>
          )}
          <div
            id={`events-list-wrapper-${eventsRowType}`}
            onWheel={(e) => {
              const el = document.getElementById(
                `events-list-wrapper-${eventsRowType}`,
              );

              if (el) {
                setShowEventsLeftScrollButton(el.scrollLeft > 0);
              }
            }}
            className={`events-list-items-wrapper ${
              props.horizontalScroll ? "events-list-items-wrapper-inline" : ""
            }`}
            data-cy="events-list-wrapper">
            {filteredEvents?.map((item: any, index: number) => {
              let showLabel = false;

              const todayDate = moment().format("YYYY/MM/DD");
              const eventDate = moment(item.startDate).format("YYYY/MM/DD");

              if (
                !props.hideDateGroupTitle &&
                (props.showEventDateTitleAlways ||
                  (!props.isCard && dateLabels.indexOf(eventDate) === -1))
              ) {
                dateLabels.push(eventDate);
                showLabel = true;
              }
              const isLast = filteredEvents?.length === index + 1;
              return (
                <div
                  key={`event_item_${index}`}
                  className={`event-list-item-container ${
                    props.isCard && isLast ? "events-list-item-last" : ""
                  }`}>
                  <EventItem
                    event={item}
                    isCard={props.isCard}
                    ignoreAttending={ignoreAttending}
                    showLabel={showLabel && !props.hideDate}
                    isToday={todayDate === eventDate}
                    notLoggedUser={props.notLoggedUser}
                    showDateInformation={props.showDateInformation}
                    {...(index === 0 &&
                      props.onShowAllClick && {
                        onShowAllClick: props.onShowAllClick,
                      })}
                    showCardRSVP={props.showCardRSVP}
                    showTimeOnly={props.showTimeOnly}
                    showDateOnly={props.showDateOnly}
                  />
                </div>
              );
            })}
          </div>
          {props.isCard && showEventsRightScrollButton && (
            <div className={"event-list-items-scroll-control"}>
              <button
                className={`event-list-items-scroll-control-button`}
                onClick={() => {
                  const el = document.getElementById(
                    `events-list-wrapper-${eventsRowType}`,
                  );
                  if (el) {
                    el.scrollLeft += SCROLL_AMOUNT;
                    setShowEventsLeftScrollButton(
                      el.scrollWidth > el.clientWidth,
                    );
                  }
                }}>
                <Chevron position={"right"} size={15} color={"#323232"} />
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventsList;
