import {
  Button,
  DropdownCheckbox,
  ErrorBox,
  FileUpload,
} from "@Components/index";
import "./MembershipDogForm.scss";
import MembershipDogFormLogic from "./MembershipDogFormLogic";
import { CircularInformation, Plus } from "@Components/icons";
import { MembershipDogFormProps } from "./MembershipDogFormPropTypes";
import MembershipDogRow from "../membership-dog-row/MembershipDogRow";

const MembershipDogForm = (componentProps: MembershipDogFormProps) => {
  const props = MembershipDogFormLogic(componentProps);
  return (
    <div className={"membership-dog-form"}>
      <div className={"membership-dog-form-title"}>
        Complete your Dog's Profile
      </div>
      <div className={"membership-dog-form-subtitle"}>
        Please provide the following details.
      </div>

      {props.dogs?.map((dogItem, index) => {
        return (
          <MembershipDogRow
            key={`dog_item_${dogItem.id}`}
            id={dogItem.id}
            index={index}
            dogName={dogItem.dogName}
            dogBreed={dogItem.dogBreed}
            showRemove={props.dogs.length > 1 && index > 0}
            removeEnabled={index > 0}
            onChange={props.onDogDetailsChange}
            onRemove={(dogRemoveIndex) => {
              props.onDogRemove(dogRemoveIndex);
            }}
          />
        );
      })}

      <div className={"membership-dog-form-add"}>
        <button
          className={"membership-dog-form-add-cta"}
          onClick={props.onAddDogRow}>
          <Plus size={15} />
          Add Dog
        </button>
      </div>

      <div className={"membership-dog-form-membership-type"}>
        <DropdownCheckbox
          isClearable={false}
          isMulti={false}
          blurOnSelect={true}
          options={componentProps.details.membershipCommunityAssets}
          optionLabel="title"
          optionValue="id"
          label="Select your preferred dog park (note that the annual membership grants access to both)"
          handleChange={props.setPreferredPark}
          optionSelected={props.preferredPark}
        />
      </div>

      <FileUpload
        label={"Upload Vaccination certificate"}
        files={props.files}
        banner={{
          icon: <CircularInformation />,
          text: "An up-to date rabies vaccination certificate is mandatory for all dogs using Greenbrook Paw Park and Bob Gardner Paw Park. Upload them so  we may process your dog park membership and/or renewal.",
        }}
        onChange={(files) => {
          props.setFiles(files);
        }}
        hideDefaultInformationMessage
      />

      <div className={"membership-dog-form-continue"}>
        {props.error && <ErrorBox message={props.error} />}
        <Button
          text={"Continue"}
          isDisabled={!props.isDogFormStepValid || props.isProcessing}
          onClick={props.onContinue}
          isLoading={props.isProcessing}
        />
      </div>
    </div>
  );
};

export default MembershipDogForm;
