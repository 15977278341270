// TODO: When we have time, we can try to refactor this one and use venues Details for both (or create a component with image, calendar and selectors that can be used bot venues and spaces).
import "./VenuesSpaces.scss";
import {
  CalendarSelect,
  DropdownCheckbox,
  Button,
  SideScroll,
} from "@Components/index";
import VenuesSpacesLogic from "./VenuesSpacesLogic";
import {
  VenuesSpaceItemProps,
  VenuesSpacesProps,
} from "./VenuesSpacesPropTypes";
import { forwardRef, useState } from "react";
import React from "react";
import { useTenantConfig } from "@App/hooks";

function VenuesSpacesItem(props: VenuesSpaceItemProps) {
  const [selectedImage, setSelectedImage] = useState(0);
  const placeholderUrl = useTenantConfig()?.features.defaultImagePlaceholder;

  return (
    <div className={"venues-spaces-items-item"}>
      <div className={"venues-spaces-items-item-left"}>
        <div className={"venues-spaces-items-item-title"}>{props.name}</div>
        <img
          src={
            props.files && props.files.length > 0
              ? props.files[selectedImage].url
              : placeholderUrl ?? ""
          }
          alt={`${props.name}`}
          className={`venues-spaces-items-item-image ${
            props.isExpanded ? "venues-spaces-items-item-image-min" : ""
          }`}
        />
        {/* </div> */}
        {!props.isExpanded ? (
          <>
            <div
              className={"venues-spaces-items-item-description"}
              dangerouslySetInnerHTML={{
                __html: props.description ?? "",
              }}
            />
            <div
              className={"venues-spaces-items-item-button"}
              data-cy={`venues-spaces-check-rates-btn-${props.index}`}>
              <Button
                text={"Check Dates and Rates"}
                onClick={props.onClick}
                isSecondary
              />
            </div>
          </>
        ) : (
          <div className={"venues-spaces-items-item-thumbnails"}>
            {props.files?.length > 1 &&
              props.files?.map((file: any, index: number) => (
                <img
                  key={`venue_space_img${index}`}
                  className={"venues-spaces-items-item-thumbnails-thumbnail"}
                  src={file?.url}
                  alt={""}
                  onClick={() => {
                    setSelectedImage(index);
                  }}
                />
              ))}
          </div>
        )}
      </div>

      {props.isExpanded && (
        <div className={"venues-spaces-items-item-right"}>
          <div className={"venues-spaces-items-item-right-top"}>
            <div className={"venues-spaces-items-item-right-top-calendar"}>
              <CalendarSelect
                availableDates={props.derivedProps.availableDates?.data?.dates!}
                selected={props.derivedProps.selectedDate}
                onMonthChange={(selectedMonthDate) => {
                  props.derivedProps.setSelectedDate(null);
                  props.derivedProps.handleMonthChange(selectedMonthDate);
                }}
                onChange={(selectedDate) => {
                  props.derivedProps.setSelectedDate(selectedDate);
                  // Update the month as well in case the user selects a day of the next/prev month.
                  props.derivedProps.handleMonthChange(selectedDate);
                  props.derivedProps.resetDropdowns();
                }}
                isVenuesView
                dataCy="calendar-select-venue-space-details"
              />
            </div>
            <div
              className={"venues-spaces-items-item-right-top-selectors"}
              data-cy="venue-space-rate-dropdowns">
              <DropdownCheckbox
                label="Select Rate"
                handleChange={props.derivedProps.handleRateChange}
                optionSelected={props.derivedProps.selectedRate}
                options={props.derivedProps.getRateOptions()}
                isLoading={props.derivedProps.isLoadingRates}
                isClearable={false}
                isMulti={false}
                blurOnSelect={true}
              />
              <DropdownCheckbox
                label="From:"
                handleChange={props.derivedProps.handleStartTimeChange}
                optionSelected={props.derivedProps.selectedStartTime}
                options={props.derivedProps.getStartTimes()}
                isLoading={props.derivedProps.isLoadingRates}
                isDisabled={!props.derivedProps.selectedRate}
                isClearable={false}
                isMulti={false}
                blurOnSelect={true}
              />
              <DropdownCheckbox
                label="To:"
                handleChange={props.derivedProps.handleEndTimeChange}
                optionSelected={props.derivedProps.selectedEndTime}
                options={props.derivedProps.getEndTimes()}
                isLoading={props.derivedProps.isLoadingRates}
                isDisabled={!props.derivedProps.selectedStartTime}
                isClearable={false}
                isMulti={false}
                blurOnSelect={true}
              />
            </div>
          </div>
          {!props.derivedProps?.isUserLogged && (
            <div className={"venues-spaces-items-item-right-banner"}>
              A valid account is needed to make a reservation. Please{" "}
              <span
                className="venues-spaces-items-item-right-banner-link"
                onClick={() => props.handleNotLoggedUserLogin()}>
                Log In
              </span>{" "}
              or{" "}
              <span
                className="venues-spaces-items-item-right-banner-link"
                onClick={() => props.handleNotLoggedUserRegister()}>
                Sign Up
              </span>
              .
            </div>
          )}
          <div
            className={"venues-spaces-items-item-right-cta"}
            data-cy="venues-spaces-details-proceed-btn">
            <Button
              onClick={() => props.handleCTAButton(props.spacePaymentData)}
              text={props.bookingCtaLabel}
              isDisabled={props.isCTAButtonDisabled}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const VenuesSpaces = forwardRef((componentProps: VenuesSpacesProps, ref) => { // eslint-disable-line
  const props = VenuesSpacesLogic(componentProps);

  const Wrapper = props.selectedSpace ? React.Fragment : SideScroll;
  return (
    <div className={"venues-spaces"}>
      <Wrapper
        {...(!props.selectedSpace && {
          verticalMargin: 50,
        })}>
        <div
          className={`venues-spaces-items ${
            props.selectedSpace ? "venues-spaces-selected" : ""
          }`}>
          {componentProps.spaces?.map((space, index) => {
            return !props.selectedSpace?.id ||
              space.id === props.selectedSpace?.id ? (
              <VenuesSpacesItem
                key={`space_item_${index}`}
                name={space.name}
                files={space.files}
                description={space.description}
                onClick={() => {
                  props.onSpaceChange(space);
                }}
                isExpanded={props.selectedSpace?.id === space.id}
                derivedProps={props}
                bookingCtaLabel={componentProps.bookingCtaLabel}
                handleCTAButton={componentProps.handleCTAButton}
                spacePaymentData={props.spacePaymentData}
                isCTAButtonDisabled={props.isCTAButtonDisabled}
                handleNotLoggedUserLogin={
                  componentProps.handleNotLoggedUserLogin
                }
                handleNotLoggedUserRegister={
                  componentProps.handleNotLoggedUserRegister
                }
                index={index}
              />
            ) : null;
          })}
        </div>
      </Wrapper>
      {props.selectedSpace && (
        <div className={"venues-spaces-bottom"}>
          <SideScroll verticalMargin={50}>
            <div className={"venues-spaces-bottom-items"}>
              {componentProps.spaces?.map((space, index) => {
                return space.id !== props.selectedSpace?.id ? (
                  <VenuesSpacesItem
                    key={`space_item_bottom${index}`}
                    name={space.name}
                    files={space.files}
                    description={space.description}
                    onClick={() => {
                      props.onSpaceChange(space);
                    }}
                    isExpanded={false}
                    derivedProps={props}
                    bookingCtaLabel={componentProps.bookingCtaLabel}
                    handleCTAButton={componentProps.handleCTAButton}
                    spacePaymentData={props.spacePaymentData}
                    isCTAButtonDisabled={props.isCTAButtonDisabled}
                    handleNotLoggedUserLogin={
                      componentProps.handleNotLoggedUserLogin
                    }
                    handleNotLoggedUserRegister={
                      componentProps.handleNotLoggedUserRegister
                    }
                    index={index}
                  />
                ) : null;
              })}
            </div>
          </SideScroll>
        </div>
      )}
    </div>
  );
});

export default VenuesSpaces;
