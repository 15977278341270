import { TENANT_ID_PROP } from "@App/constants/appConstants";
import { useState } from "react";
import { config } from "@App/config/config";

const DevMenuLogic = () => {
  const [tenants] = useState([
    { label: "LakewoodRanch", value: "lakewoodranch" },
    { label: "Cecilian Partners", value: "cecilianpartners" },
    { label: "Rancho Sahuarita", value: "rancho" },
  ]);
  const [selectedTenant] = useState(localStorage.getItem(TENANT_ID_PROP) ?? "");
  const [configuration] = useState(JSON.stringify(config, null, "\t"));

  const onTenantChange = (tenant: any) => {
    if (!!tenant?.value && tenant.value !== selectedTenant) {
      localStorage.setItem(TENANT_ID_PROP, tenant.value);
      window.location.reload();
    }
  };

  const onTenantReset = () => {
    localStorage.removeItem(TENANT_ID_PROP);
    window.location.reload();
  };

  return {
    tenants,
    selectedTenant,
    configuration,
    onTenantChange,
    onTenantReset,
  };
};

export default DevMenuLogic;
