import { tenantConfigGet } from "@App/api/general";
import { DEFAULT_MOBILE_MEDIA_QUERY } from "@App/constants/appConstants";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { Tenant } from "@App/models/tenant";
import { getUpcomingEvents } from "@App/store/actions/eventActions";
import { UpcomingEventsState } from "@App/store/reducers/eventReducer";
import { RootState } from "@App/store/store";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const HomeLogic = () => {
  const storeUpcomingEvents = useSelector<RootState, UpcomingEventsState>(
    (state) => state.upcomingEventsData,
  );
  const [hasFavOrRsvp, setHasFavOrRsvp] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState(
    storeUpcomingEvents.upcomingEvents,
  );
  const [tenant, setTenant] = useState<Tenant>();
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches,
  );
  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUpcomingEvents({}) as any);
  }, [dispatch]);

  useEffect(() => {
    const onResize = (event: any) => {
      setIsMobileScreen(window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    setTenant(tenantConfig);
  }, [tenantConfig]);

  useEffect(() => {
    setHasFavOrRsvp(
      storeUpcomingEvents.upcomingEvents?.some(
        (e) => e.user?.isFavorite || e.user?.isRSVPd || e.user?.isAttending,
      ),
    );
    setUpcomingEvents(storeUpcomingEvents.upcomingEvents);
  }, [storeUpcomingEvents, hasFavOrRsvp]);
  return { tenant, hasFavOrRsvp, upcomingEvents, isMobileScreen };
};

export default HomeLogic;
