import { useEffect, useState } from "react";
import { Dog } from "../membership-dog-row/MembershipDogRowPropTypes";
import { fileExtensionRegx, generateId } from "@Utils/utils";
import { MembershipDogFormProps } from "./MembershipDogFormPropTypes";
import { MEMBERSHIP_DOGS } from "@App/constants/appConstants";
import { fileUpload, presignedUrlGet } from "@App/api/general";
import {
  addFileUserMembership,
  createUserMembership,
} from "@App/api/memberships";
import { useSelector } from "react-redux";
import { RootState } from "@App/store/store";
import { UserState } from "@App/store/reducers/userReducer";
import { config } from "@App/config/config";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { tenantConfigGet } from "@App/api/general";
import { useQuery } from "@tanstack/react-query";
import { Tenant } from "@App/models/tenant";

const MembershipDogFormLogic = (props: MembershipDogFormProps) => {
  const [dogs, setDogs] = useState<Dog[]>([
    { id: generateId(), index: 0, dogName: "", dogBreed: "" },
  ]);

  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  const [preferredPark, setPreferredPark] = useState<any>();
  const [isDogFormStepValid, setIsDogFormStepValid] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const { userInfo } = userData;

  useEffect(() => {
    let validDogs = true;
    dogs.forEach((dog) => {
      if (
        files?.length === 0 ||
        (validDogs && dog.dogName.length <= 1) ||
        dog.dogBreed.length <= 1
      ) {
        validDogs = false;
      }
      setIsDogFormStepValid(validDogs && !!preferredPark);
    });
  }, [dogs, preferredPark, files]);

  const onAddDogRow = () => {
    const dogsList = [
      ...dogs,
      {
        id: generateId(),
        index: dogs.length,
        dogName: "",
        dogBreed: "",
      },
    ];
    setDogs(dogsList);
  };

  const onDogDetailsChange = (dog: Dog) => {
    const updatedDogList = dogs?.map((updatedListDogItem) => {
      if (updatedListDogItem.index === dog.index) {
        return {
          id: dog.id,
          index: dog.index,
          dogName: dog.dogName,
          dogBreed: dog.dogBreed,
        };
      }
      return updatedListDogItem;
    });
    setDogs(updatedDogList);
  };

  const onDogRemove = (dogRemoveIndex: number) => {
    const filteredDogList: Dog[] = [];
    let dogIndex = 0;
    dogs.forEach((dogItem) => {
      if (dogItem.index !== dogRemoveIndex) {
        filteredDogList.push({
          id: dogItem.id,
          index: dogIndex,
          dogName: dogItem.dogName,
          dogBreed: dogItem.dogBreed,
        });
        dogIndex++;
      }
    });
    setDogs(filteredDogList);
  };

  const onContinue = async () => {
    setIsProcessing(true);
    localStorage.setItem(
      MEMBERSHIP_DOGS,
      JSON.stringify({
        dogs,
        preferredPark: preferredPark.title,
        title: props.title,
        files: props.details?.files,
      }),
    );

    // TODO: isActive shouldn't be set in the payload, should be handled in the BE API
    // TODO: application.appliedDate probably should be set in the BE API too
    const membershipRequest: any = {
      communityAssetId: preferredPark?.id,
      userId: userInfo?.id,
      membershipId: props.details.id,
      isActive: true,
      application: {
        appliedDate: new Date().toISOString(),
        formFields: [],
        groupedFormFields: [],
      },
    };
    const dogFormFields: any = [];
    dogs.forEach((dog) => {
      const fields = props.details.application.groupedFormFields.map(
        (group) => {
          return {
            ...group,
            fields: group.fields.map((field) => {
              if (field.order === 1) {
                return {
                  id: field.id,
                  value: dog.dogName,
                  order: field.order,
                };
              } else {
                return {
                  id: field.id,
                  value: dog.dogBreed,
                  order: field.order,
                };
              }
            }),
          };
        },
      );
      dogFormFields.push(...fields);
    });
    membershipRequest.application.groupedFormFields = dogFormFields;
    let res: any = null;
    try {
      res = await createUserMembership(membershipRequest);

      if (res.status === 200) {
        files.forEach(async (file, index) => {
          const fileExtensions = fileExtensionRegx.exec(file.name);
          if (fileExtensions && fileExtensions.length > 0) {
            const fileExtension = fileExtensions[1];
            if (fileExtension) {
              const getPresignedUrlResponse =
                await presignedUrlGet(fileExtension);
              if (getPresignedUrlResponse.data) {
                const uploadFileResponse = await fileUpload({
                  presignedUrl: getPresignedUrlResponse?.data.preSignedURL,
                  file: file.file,
                });

                if (uploadFileResponse) {
                  await addFileUserMembership(res.data, {
                    id: getPresignedUrlResponse.data.id,
                    fileName: file.name,
                    sort: index,
                  });
                }
              }
            }
          }
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        setError(
          `It appears that you already have an existing membership application associated with your profile. 
          Please contact us at ${
            tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
            config.defaultAdminEmail
          } for more details.`,
        );
      } else {
        setError(
          `It appears that there was an error with your membership application. 
          Please contact us at ${
            tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
            config.defaultAdminEmail
          } for more details.`,
        );
      }
    } finally {
      setIsProcessing(false);
    }
    if (res?.data) {
      props.onContinue(res.data, userInfo?.id!);
    }
  };

  return {
    dogs,
    preferredPark,
    isDogFormStepValid,
    files,
    onAddDogRow,
    onDogDetailsChange,
    onDogRemove,
    setPreferredPark,
    onContinue,
    setFiles,
    error,
    isProcessing,
  };
};

export default MembershipDogFormLogic;
