import { Navbar } from "@Layouts/index";
import MembershipDetailsLogic from "./MembershipDetailsLogic";
import { GenericDetails, MembershipModal } from "@Components/index";

const MembershipDetails = () => {
  const props = MembershipDetailsLogic();
  return (
    <Navbar>
      {props.details && (
        <GenericDetails
          details={props.details}
          goBack={props.goBack}
          primaryButton={{
            loggedText: props.ctaText,
            notLoggedText: "Log In to apply",
            loggedAction: props.onApplyClick,
            notLoggedAction: () => props.handleNotLoggedUserLogin(),
          }}
          pricingData={{
            title: "Membership Prices",
            prices: props.details.rates,
          }}
        />
      )}
      <MembershipModal
        membershipDetails={props.details}
        isVisible={props.dogMembershipModalVisible}
        onClose={props.membershipModalClose}
        userType={props.userInfo?.userType}
      />
    </Navbar>
  );
};

export default MembershipDetails;
