import { useEffect, useState } from "react";
import { config } from "@App/config/config";
import { FileSize } from "./FileUploadPropTypes";

//TODO: When we have time, try to add the currentFiles in the hook
let currentFiles: any[] = [];

const FileUploadLogic = (
  files?: any,
  onChange?: (files: any) => void,
  hideDefaultInformationMessage?: boolean,
) => {
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null,
  );
  const [informationMessage, setInformationMessage] = useState<string | null>(
    hideDefaultInformationMessage
      ? null
      : `Please check image size(s) before uploading.`,
  );

  useEffect(() => {
    currentFiles = [];
    if (files) {
      files.forEach((fileItem: any, index: number) => {
        addThumbnail({
          index,
          imageUrl: fileItem.url.replace(" ", "%20"), //update urls that have white space(s)
          isImageUploaded: true,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFileSize = (bytes: number, decimals = 2): FileSize => {
    if (!+bytes) return { size: 0, type: "Bytes" };

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {
      size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
      type: sizes[i],
    };
  };

  const validateImage = (file: File): string | null => {
    const supportedImageTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
    ];
    const isValidType = supportedImageTypes.indexOf(file.type) > -1;
    let message = null;

    if (!isValidType) {
      message = `"${file.name}" - File should be PNG, JPG, JPEG or PDF.`;
    } else {
      const fileSize = getFileSize(file.size);
      const errorMessage = `"${file.name}" - File size should be less than ${config.maxFileSizeMB} MB`;
      switch (fileSize.type) {
        case "Bytes":
        case "KB":
          break;
        case "MB":
          if (fileSize.size > config.maxFileSizeMB) {
            message = errorMessage;
          }
          break;
        default:
          message = errorMessage;
          break;
      }
    }

    return message;
  };

  const getFiles = (items: DataTransferItem[]): File[] => {
    let fileItems: File[] = [];
    items.forEach((item) => {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          fileItems.push(file);
        }
      }
    });
    return fileItems;
  };

  const addThumbnail = ({
    index,
    isImageUploaded,
    imageUrl,
    file,
  }: {
    index: number;
    isImageUploaded?: boolean;
    imageUrl?: string;
    file?: File;
  }) => {
    const fileUploadPreview = document.getElementById(
      "FileUploadPreviewWrapper",
    );
    if (fileUploadPreview && (file || imageUrl)) {
      const fileId = currentFiles.length;
      currentFiles = currentFiles.concat({
        id: fileId,
        name: file ? file.name : imageUrl,
        file,
      });
      onChange && onChange(currentFiles);

      const isPrimaryImage =
        index === 0 && fileUploadPreview?.childElementCount === 0;

      // Image thumbnail
      const htmlImage = document.createElement("div");
      htmlImage.className = `file-upload-preview-image ${
        isImageUploaded ? "" : "file-upload-preview-image-new"
      }`;
      htmlImage.style.backgroundImage = `url(${
        file ? URL.createObjectURL(file) : imageUrl
      })`;

      //image Wrapper
      const imageHtmlWrapper = document.createElement("div");
      imageHtmlWrapper.innerHTML = isPrimaryImage
        ? "<div>Primary Image</div>"
        : "";
      imageHtmlWrapper.className = isPrimaryImage
        ? "file-upload-text"
        : "file-upload-preview-image-wrapper";

      // Delete/trash button
      const trashIcon = `<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z" fill="#32373F"/></svg>`;
      const deleteHtmlButton = document.createElement("button");
      deleteHtmlButton.className = "file-upload-preview-delete";
      deleteHtmlButton.innerHTML = trashIcon;
      deleteHtmlButton.onclick = () => {
        //TODO: To be done when we have the backend support for this
        if (isImageUploaded) {
          alert("Delete Image backend call");
        } else {
          imageHtmlWrapper.remove();
          currentFiles = currentFiles.filter(
            (fileItem) => fileItem.id !== fileId,
          );
          onChange && onChange(currentFiles);
        }
      };

      htmlImage.appendChild(deleteHtmlButton);
      imageHtmlWrapper.appendChild(htmlImage);
      fileUploadPreview.appendChild(imageHtmlWrapper);
    }
  };

  const handleLocalFileUpload = ({
    event,
    browseFiles,
  }: {
    event?: React.DragEvent<HTMLDivElement> | null;
    browseFiles?: FileList | null;
  }) => {
    if (event) {
      event.preventDefault();
    }
    const fileItems = event
      ? getFiles([...event.dataTransfer.items])
      : browseFiles
        ? Array.from(browseFiles)
        : [];

    if (fileItems?.length > 0) {
      fileItems.forEach((file, index) => {
        const fileValidationMessage = validateImage(file);
        if (fileValidationMessage) {
          setValidationMessage(fileValidationMessage);
        } else {
          setValidationMessage(null);
          setInformationMessage(null);
          addThumbnail({ index, file });
        }
      });
    }
  };

  return {
    validationMessage,
    informationMessage,
    handleLocalFileUpload,
  };
};

export default FileUploadLogic;
